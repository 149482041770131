import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Component/Header/Header";

const NewPage = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header showSingInWithTwitterButton={props.showSingInWithTwitterButton} />
      {props.children}
    </React.Fragment>
  );
};

NewPage.prototype = {
  showSingInWithTwitterButton: PropTypes.bool
};

export default NewPage;
