import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext2 from "../../Context/AuthContext";
import bluelogo from "../../images/bluelogo.png";
import xLogo from "../../images/logo-white.png";
import { resetState, stopTicker } from "../Spot/GameEngine";

import "./header.css";

function Header(props) {
  const ctx = useContext(AuthContext2);
  const {
    isLoggedIn,
    userName,
    profileImage,
    // hasMetaMask,
    // setNoMetaMaskDialogueOpen,
  } = ctx;

  // const loader = useRef(false);
  // const location = useLocation();

  /** Menu user Name */
  useEffect(() => {
    resetState();
    stopTicker();
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  // const getToken = () => {
  //   // window.location.href = `${process.env.REACT_APP_BACKEND_BASE}user/login/twitter`;
  //   window.open("https://twitter.com/NodeAliens", "_blank");
  // };

  return (
    <div>
      <div className="headerContainer">
        <div className="linksContainer">
          <Link className="li" target="__blank" to={"https://figmashort.link/zha8rB"}>
            ALIENS
          </Link>
          <Link className="li" to="/roadmap">
            ROADMAP
          </Link>
          <Link className="li" to="/">
            <div className="logo">
              <img src={bluelogo} alt="home" />{" "}
            </div>{" "}
          </Link>
          <Link className="li" to="/nmatika">
            NMATIKA
          </Link>
          <Link className="li" to="/faq">
            FAQ
          </Link>
        </div>
        {isLoggedIn && (
          <Link className="li" to="/spot">
            SPOT GAME
          </Link>
        )}

        {props.showSingInWithTwitterButton && <div className="li" id="logo-container">
          {isLoggedIn ? (
            <>
              <Avatar
                sx={{ bgcolor: "#2bcbe5" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                src={profileImage}
                alt={userName}
              ></Avatar>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div>{userName}</div>
                <Link
                  style={{
                    margin: "0.5rem 0",
                  }}
                  onClick={() => ctx.logout()}
                >
                  {" "}
                  Logout{" "}
                </Link>
              </Menu>
            </>
          ) : (
            <Link to={"https://twitter.com/NodeAliens"} target="__blank">
              <div className="twitterlogo">
                <img src={xLogo} alt="login-with-twitter" />
              </div>
            </Link>
          )}
        </div>}
      </div>
    </div>
  );
}

export default Header;
