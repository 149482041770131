import * as React from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import eight from "../../images/gallery/eight.png";
import eighteen from "../../images/gallery/eighteen.png";
import eleven from "../../images/gallery/eleven.png";
import fifteen from "../../images/gallery/fifteen.png";
import five from "../../images/gallery/five.png";
import four from "../../images/gallery/four.png";
import fourteen from "../../images/gallery/fourteen.png";
import nine from "../../images/gallery/nine.png";
import nineteen from "../../images/gallery/nineteen.png";
import one from "../../images/gallery/one.png";
import seven from "../../images/gallery/seven.png";
import seventeen from "../../images/gallery/seventeen.png";
import six from "../../images/gallery/six.png";
import sixteen from "../../images/gallery/sixteen.png";
import ten from "../../images/gallery/ten.png";
import thirteen from "../../images/gallery/thirteen.png";
import three from "../../images/gallery/three.png";
import twelve from "../../images/gallery/twelve.png";
import twenty from "../../images/gallery/twenty.png";
import twentyone from "../../images/gallery/twentyone.png";
import twentythree from "../../images/gallery/twentythree.png";
import twentytwo from "../../images/gallery/twentytwo.png";
import two from "../../images/gallery/two.png";
import zero from "../../images/gallery/zero.png";
import "./gallery.css";

function Gallery() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Gallery - Node Aliens">
      <div>
        <Loader>
          <div>
            <div>
              <div className="galaryContainer">
                <h4>Gallery</h4>
                <div className="galary_outer">
                  <div className="dropdown_outer">
                    <div className="dropDown">
                      <select>
                        <option selected="">Background</option>
                        <option value="1">AQUAMARINE</option>
                        <option value="2">ARMY GREEN</option>
                        <option value="3">BLUE</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Clothes</option>
                        <option value="1">ADMIRALS COAT</option>
                        <option value="2">BAYC T BLACK</option>
                        <option value="3">BAYC T RED</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Head Pieces</option>
                        <option value="1">CROSS</option>
                        <option value="2">DIAMOND STUD</option>
                        <option value="3">GOLD HOOP</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Eyes</option>
                        <option value="1">3D</option>
                        <option value="2">ANGRY</option>
                        <option value="3">BLINDFOLD</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Skin</option>
                        <option value="1">Skin 1</option>
                        <option value="2">Skin 2</option>
                        <option value="3">Skin 3</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Thoughts</option>
                        <option value="1">Thoughts 1</option>
                        <option value="2">Thoughts 2</option>
                        <option value="3">Thoughts 3</option>
                      </select>
                    </div>

                    <div className="dropDown">
                      <select>
                        <option selected="">Mouth</option>
                        <option value="1">BORED</option>
                        <option value="2">BORED CIGAR</option>
                        <option value="3">BORED DAGGER</option>
                      </select>
                    </div>

                    <button className="resetBtn">Reset Filter</button>
                  </div>

                  <div className="galary_image_container">
                    <div className="galary_image_container_inner">
                      <img src={zero} />
                      <h5>Third Alien #0</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={one} />
                      <h5>Third Alien #1</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={two} />
                      <h5>Third Alien #2</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={three} />
                      <h5>Third Alien #3</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={four} />
                      <h5>Third Alien #4</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={five} />
                      <h5>Third Alien #5</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={six} />
                      <h5>Third Alien #6</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={seven} />
                      <h5>Third Alien #7</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={eight} />
                      <h5>Third Alien #8</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={nine} />
                      <h5>Third Alien #9</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={ten} />
                      <h5>Third Alien #10</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={eleven} />
                      <h5>Third Alien #11</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={twelve} />
                      <h5>Third Alien #12</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={thirteen} />
                      <h5>Third Alien #13</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={fourteen} />
                      <h5>Third Alien #14</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={fifteen} />
                      <h5>Third Alien #15</h5>
                    </div>

                    {/* /***********************************************************************************************/}

                    <div className="galary_image_container_inner">
                      <img src={sixteen} />
                      <h5>Third Alien #16</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={seventeen} />
                      <h5>Third Alien #17</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={eighteen} />
                      <h5>Third Alien #18</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={nineteen} />
                      <h5>Third Alien #19</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={twenty} />
                      <h5>Third Alien #20</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={twentyone} />
                      <h5>Third Alien #21</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={twentytwo} />
                      <h5>Third Alien #22</h5>
                    </div>

                    <div className="galary_image_container_inner">
                      <img src={twentythree} />
                      <h5>Third Alien #23</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* {isLoggedIn && activeWallet?.toLowerCase() !== walletAddress && (
                <SpeedDial
                  className="metaMaskCircleBtn"
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img className="metaMaskCircleBtnIcon" src={metaMask} />
                  }
                  onClick={connectWallet}
                />
              )} */}
            </div>
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Gallery;
