import * as React from "react";
// import { useContext } from "react";
// import AuthContext from "../../Context/AuthContext";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
// import metaMask from "../../assets/images/metaMask.png";
import classes from "./nmatika.module.css";

function Nmatika() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Nmatika - Node Aliens">
      <div>
        <Loader>
          <div>
            <div className={classes.missionSection}>
              <div className={classes.missionContainer}>
                <h4>THE NMATIKA</h4>
                <div className={classes.ytframe}>
                  <iframe width="100%" height="500" src="https://www.youtube.com/embed/h9s50y_LjzM?si=e6OhQZaETfzHh4Dy?autoplay=1" title="Node Aliens Revolt" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                {/* <div className={classes.missionTxt}>
                  <p>Aliens Embrace the Future: The Future is AI and Digital Art.</p>
                  <p>
                    The world of digital art, already at the crossroads of technology and creativity, is about to take a significant step forward with Generative AI. This technology stands out for its ability to craft unique and often unexpected pieces of digital art, challenging our traditional views on creativity and art ownership.
                  </p>
                  <p>
                    Understanding Generative AI.
                  </p>
                  <p>
                    Generative AI is a branch of artificial intelligence that generates new content, like images, music, text, or code, from large datasets. It goes beyond traditional AI by not just analyzing inputs but by creating new, original outputs.
                  </p>
                  <p>The Impact</p>
                  <p>Creative Possibilities: Generative AI allows artists to explore new styles and ideas, broadening the range of artistic expression. We can expect a surge of varied and fresh artworks.</p>
                  <p>Accessibility and Inclusivity: This technology makes art creation more accessible, inviting people with different skills and backgrounds to contribute to the art world.</p>
                  <p>Personalization and Interactivity: Generative AI can tailor art to specific preferences, enhancing engagement with the artwork.</p>
                  <p>New Business Models: It paves the way for novel approaches in the art market, like series creation, customizable pieces, and unique collaborations between artists and AI.</p>
                  <p>Our Platform</p>
                  <p>We aim to be the go-to platform for generative art, focusing on both the creation and sale of AI-generated artwork. Our platform is user-friendly, inclusive, and interactive, merging high-tech with ease of use. Artists can easily turn their AI art into NFTs, while collectors enjoy a simple process for discovering and buying unique art. More than a marketplace, we're building a community for learning, sharing, and innovating at the junction of art and technology.</p>
                </div> */}
              </div>
            </div>

            {/* {isLoggedIn &&
              walletAddress?.toLowerCase() !== activeWallet?.toLowerCase() && (
                <SpeedDial
                  className={classes.metaMaskCircleBtn}
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      className={classes.metaMaskCircleBtnIcon}
                      src={metaMask}
                      alt="connect-to-metamask"
                    />
                  }
                  onClick={connectWallet}
                />
              )} */}
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Nmatika;
