import { default as React, useEffect } from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import TableSound from "../../assets/media/Cleaning-Table.wav";
import OPERATINGTABLEMOBILE from "../../images/OPERATINGTABLEMOBILE.jpeg";
import "./table.css";
import {ToggleAudioButton, useBackgroundAudio} from "../../Utils/audioUtils";

function Table() {
  useBackgroundAudio(TableSound)

  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Table - Node Aliens">
      <div>
        <Loader>
          <div>
            
        <ToggleAudioButton />
            <div className="tableBg">
              <img className="mobileView" src={OPERATINGTABLEMOBILE} />
            </div>

            {/* {isLoggedIn &&
              walletAddress?.toLowerCase() !== activeWallet?.toLowerCase() && (
                <Button
                  className="metaMaskBtn"
                  variant="outlined"
                  startIcon={
                    <img
                      className="metamaskicon"
                      src={metaMask}
                      alt="connect-to-metamsk"
                    />
                  }
                  onClick={() => connectWallet()}
                >
                  Connect to Metamask Wallet
                </Button>
              )} */}
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Table;
