import * as React from "react";
import gardenSound from "../../assets/media/Garden-ver2.wav";
import GARDENDESKTOP from "../../images/GARDENDESKTOP.jpeg";
import {ToggleAudioButton, useBackgroundAudio} from "../../Utils/audioUtils"
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import "./garden.css";

function Garden() {
  // const ctx = useContext(AuthContext2);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;
  useBackgroundAudio(gardenSound)

  return (
    <NewPage title="Garden - Node Aliens">
      <div>
        <ToggleAudioButton />
        <Loader>
          <div className="gardenBg">
            {" "}
            <img className="mobileView" src={GARDENDESKTOP} />
          </div>
          {/* {isLoggedIn &&
            walletAddress?.toLowerCase() !== activeWallet?.toLowerCase() && (
              <Button
                className="metaMaskBtn"
                variant="outlined"
                startIcon={
                  <img
                    className="metamaskicon"
                    src={metaMask}
                    alt="connect-to-metamask"
                  />
                }
                onClick={connectWallet}
              >
                Connect to Metamask Wallet
              </Button>
            )} */}
        </Loader>
      </div>
    </NewPage>
  );
}

export default Garden;
