import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function useDialog() {
  const [data, setData] = React.useState({heading: null, content: null})

  const handleClickOpen = (heading, content) => {
    setData({heading, content})
  };

  const handleClose = () => {
    setData({heading: null, content: null})
  };

  const showDialog = (heading, content) => handleClickOpen(heading, content)

  const MUIDialog = () => (
    <React.Fragment>
      <Dialog
        open={Boolean(data.heading)}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {data.heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  return {MUIDialog, showDialog}
}
