import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import AlienstalkingSound from "../../assets/media/Aliens-talking-Background.wav";
import audio10 from "../../assets/media/Line-10_It-transforms.wav";
import audio11 from "../../assets/media/Line-11_Our-journey-will.wav";
import audio12 from "../../assets/media/Line-12_We-will-be-joined.wav";
import audio13 from "../../assets/media/Line-13_Abandoned-by-some.wav";
import audio16 from "../../assets/media/Line-16_Prepare-for-the-awakening.wav";
import audio2 from "../../assets/media/Line-2_The-old-world.wav";
import audio3 from "../../assets/media/Line-3_We-Must-Rebuild.wav";
import audio5 from "../../assets/media/Line-5_A-Small-community.wav";
import audio7 from "../../assets/media/Line-7_Many-to-infinity.wav";
import secondMobile from "../../images/secondMobile.png";
import "./second.css";
import {ToggleAudioButton, useBackgroundAudio} from "../../Utils/audioUtils";
import AuthContext2 from "../../Context/AuthContext";

function Second() {
  const ctx = React.useContext(AuthContext2);
  const {allowAudio} = ctx
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  const {stopAudio} = useBackgroundAudio(AlienstalkingSound)

  // let checkaudio = new Audio(AlienstalkingSound);

  const playMessage = (audioId) => {
    if(allowAudio){
      document.getElementById(audioId).play();
      stopAudio();
    }
  }

  return (
    <NewPage title="Second - Node Aliens">
      <div>
        <Loader>
        <ToggleAudioButton />
          <div>
            <div>
              <div className="secondBg">
                {" "}
                <img className="mobileView" src={secondMobile} />
                <Link to="/garden" className="garden-link"></Link>
                <Link to="/table" className="table-link"></Link>
                <Link to="/temple" className="middle-link"></Link>
                <Link to="/middle" className="temple-link"></Link>
                <span
                  id="audio_outer11"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner11")}
                >
                  <audio id="audio_inner11" src={audio11}></audio>
                </span>
                <span
                  id="audio_outer10"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner10")}
                >
                  <audio id="audio_inner10" src={audio10}></audio>
                </span>
                <span
                  id="audio_outer2"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner2")}
                >
                  <audio id="audio_inner2" src={audio2}></audio>
                </span>
                <span
                  id="audio_outer13"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner13")}
                >
                  <audio id="audio_inner13" src={audio13}></audio>
                </span>
                <span
                  id="audio_outer5"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner5")}
                >
                  <audio id="audio_inner5" src={audio5}></audio>
                </span>
                <span
                  id="audio_outer16"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner16")}
                >
                  <audio id="audio_inner16" src={audio16}></audio>
                </span>
                <span
                  id="audio_outer3"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner3")}
                >
                  <audio id="audio_inner3" src={audio3}></audio>
                </span>
                <span
                  id="audio_outer7"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner7")}
                >
                  <audio id="audio_inner7" src={audio7}></audio>
                </span>
                <span
                  id="audio_outer12"
                  className="audio-control"
                  onClick={() => playMessage("audio_inner12")}
                >
                  <audio id="audio_inner12" src={audio12}></audio>
                </span>
              </div>

              {/* {isLoggedIn &&
                walletAddress?.toLowerCase() !==
                  activeWallet?.toLowerCase() && (
                  <SpeedDial
                    className="metaMaskCircleBtn"
                    ariaLabel="SpeedDial basic example"
                    icon={
                      <img className="metaMaskCircleBtnIcon" src={metaMask} />
                    }
                    onClick={connectWallet}
                  />
                )} */}
            </div>
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Second;
