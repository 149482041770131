import * as React from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import "./roadmap.css";
import IdeaMap from "../../images/ideamap.jpeg";
function Roadmap() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Roadmap - Node Aliens">
      <div>
        <Loader>
          <div>
            <div className="missionSection">
              <div className="missionContainer">
                <img src={IdeaMap} width={"100%"} height={"100%"} alt="roadmap" />
                {/* <h4>THE ROADMAP</h4>
                <div className="missionTxt">
                  <p>We're going to a new world.</p>
                  <p>
                    A new decentralized world with a community-owned economy.
                  </p>
                  <p>
                    We're going to be an open economy where the community
                    creates the rules.
                  </p>
                  <p>
                    We're going to be artists who spread knowledge with love.
                  </p>
                  <p>We're going to shake up the establishment.</p>
                  <p>We're going to live by the new web3 ethos.</p>
                  <p>We are rebels from another planet. We are misfits. </p>
                  <p>We are aliens</p>
                </div> */}
              </div>
            </div>

            {/* {isLoggedIn &&
              walletAddress?.toLowerCase() !== activeWallet?.toLowerCase() && (
                <SpeedDial
                  className="metaMaskCircleBtn"
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      className="metaMaskCircleBtnIcon"
                      src={metaMask}
                      alt="connect-to-metamask"
                    />
                  }
                  onClick={connectWallet}
                />
              )} */}
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Roadmap;
