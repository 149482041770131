export const faqData = [
  {
    que: "What are Node Aliens?",
    ans: "Node Aliens are the collection built on 18 months of blood, sweat, and tears. Maybe not everyone will love our art, but they’ll appreciate the effort we put into it. These collectible are an entry point into our community. If you want to read our manifesto. Check it out in our discord. " 
  },
  {
    que: "How can I buy one?",
    ans: "You can participate in our presale round on Helio or purchase one during our mint on Magic Eden." 
  },
  {
    que: "Why did you partner with Helio?",
    ans: "Helio offers a comprehensive and customizable platform for managing and marketing NFT pre-sales. Their services include multiple payment options (including credit/debit cards), tools for capturing customer information, managing pre-sale links, and setting sales limits. Additionally, Helio's marketing support, 24/7 assistance, and collaboration opportunities can significantly enhance our outreach and efficiency in the pre-sale phase.",
  },
  {
    que: "Why did you spend months creating an alien language?",
    ans: "This is much more than an art project. We took innovative approach to engage the community by inviting them to decode hidden messages and participate in a larger narrative, fostering a sense of belonging and investment in the project's lore. Additionally, incorporating such unique elements can distinguish the project in a crowded market, making it more memorable and potentially increasing its perceived value among collectors and enthusiasts. It’s also the foundation for our alien worlds. This is much more than an art project. "  
  },
  {
    que: "Why did it take so long to complete your art?",
    ans: "We wanted something that that exhibited high quality and Uniqueness: Extensive time investment ensures the art is of high quality and unique, which can significantly increase its appeal and value in the competitive NFT market. We kept starting over to until we fell in love with our aliens. One day, we’re goingt to a detailed breakdown of the process and short the evolution of the project"
  },
  {
    que: "Why do I hear weird sounds and see weird things on the walls on the website?",
    ans: "Easter Eggs"
  },
  {
    que: "What blockchain are Node Aliens on?",
    ans: "Solana!",
  },
  {
    que: "Will there be an allow list?",
    ans: "Yes, there is an allow list. Get yourself on the list asap. If you miss out, we’ll also have a public mint as well. ",
  },
  {
    que: "What are the maximum mints per wallet?",
    ans: "There is a max of 2 aliens per wallet at mint.",
  },
];
