/**
 * This is Game Engine of Spot Game.
 * As This Game generates almost whole graphical interface via createJs library, it is better to saperate down full code outside react component to prevent re-renders.
 */

import { Howl } from "howler";
import instance from "../../Utils/AxiosInstance";

// Constants
const TTQ = 5;

// HTML Elements
let proT; //pre loader text
let startCont, tileCont; // containers
let timeT, scoreT, levelT; // Tiles
let btStart; // start button
let topBack; // audio button

let upAr, downAr, leftAr, rightAr;

let btMusic;
let backScene;
let messWin, hintWin;
let qtext;
let loader;
let proBar;

let proBarn = null;
let sbox;
let sbox2;

let canvas;
let stage;

// Other Variables

let timeInter; // time intervel
let imgArray;
let rightS, wrongS, backgS, resS, transS;
let stWidth, stHeight; //stage dimensions
let ctiles = 0;
export let isLoading = false;
let mobMess;
let kname = "";
let leadCont;
let totalR = 0;

let isMob = false;
let realArray = [];
let nameArray = [];
let gameState = "end";
let canClick = false;
let oldItems = [];

let corArr;
let questArray;

let isWrong = false;
let isMusic = true;
let isHint = true;
let currentItem;
let gamePause = false;
let currentQ = 0;
let TTL = 3;
let level = 1;
let imgc = 0;
let score = 0;
let prevX, prevY;
let msec = 0;
let sec = 0;
let min = 0;
let total_sec = 600;
let total_right = 0;
let haveClicked = false;

let userName = "user";
let profileImage = "null";
let userId = "";
let totalQuestions = 0;
let totalFounds = 0;
let toggleIsLeaderboardOpen;
let hintArray = [];
let currentHint = "";
let mcircle;

export const configureVars = (name, id, image, setIsLeaderboardOpen) => {
  userName = name;
  profileImage = image;
  userId = id;
  toggleIsLeaderboardOpen = setIsLeaderboardOpen;
};

export const shareOnTwitter = async () => {
  try {
    console.info(score, sec, totalFounds);
    const data = await instance.post("game/share-on-twitter", {
      score,
      found: totalFounds,
      time: 20,
    });
    return data.data;
  } catch (error) {
    return { error };
  }
};

export const stopTicker = () => {
  window.createjs.Ticker.removeEventListener("tick", updateGame);
};

export const resetState = () => {
  sbox?.removeEventListener("click", getTheLeaderBoard);
  sbox2?.removeEventListener("click", getTheLeaderBoard);
  proBarn = null;

  // Variables

  gameState = "end";
  score = 0;
  timeInter && clearInterval(timeInter);
  totalFounds = 0;
  backgS?.stop();
  totalQuestions = 0;
  msec = 0;
  sec = 0;
  min = 0;
  level = 1;
  currentQ = 0;
  gamePause = false;
  ctiles = 0;
  kname = "";
  totalR = 0;
  isMob = false;
  realArray = [];
  nameArray = [];
  oldItems = [];
  canClick = false;
  isWrong = false;
  isMusic = true;
  TTL = 3;
  imgc = 0;
  haveClicked = false;
  total_right = 0;
  total_sec = 600;

  ////////////
};

const addBmp = (bname, tx, ty, isR) => {
  const bmp = new window.createjs.Bitmap(loader.getResult(bname));
  if (isR) {
    bmp.regX = bmp.image.width / 2;
    bmp.regY = bmp.image.height / 2;
  }
  bmp.y = ty;
  bmp.x = tx;
  return bmp;
};

const PreventScrollOnTouchDevice = (e) => {
  e &&
    e.nativeEvent &&
    e.nativeEvent.preventDefault &&
    e.nativeEvent.preventDefault();
  e.preventDefault();
};

const handleProgress = (e) => {
  const progresPrecentage = Math.round(loader.progress * 100);
  proT.text = `${progresPrecentage}%`;
  if (progresPrecentage >= 100) {
    e.target.removeEventListener("progress", handleProgress);
  }
};

const optimizeForTouchAndScreens = () => {
  if (window.createjs.Touch.isSupported()) {
    window.createjs.Touch.enable(stage, false, true);
    stage.preventSelection = false;
    isMob = true;
  }
};

//assets loaded
const handleComplete = (e) => {
  e.target.removeEventListener("complete", handleComplete);
  stage.removeChild(proBar);
  proBar = null;
  stage.removeChild(proT);
  const imgArrayRaw = [
    "GBack1",
    "GBack2",
    "GBack3",
    "GBack4",
    "GBack5",
    "GBack6",
    "GBack7",
    "GBack8",
    "GBack9",
  ];
  imgArray = shuffle(imgArrayRaw);
  realArray = [
    'Which project was known as "WORST NFT PROJECT EVER"?',
    "Who is the most notorious NFT influencer that was running scams?",
    "This founder/marketer went on CNBC live from Dubai on 2/10/2022 and created this meme",
    "This project represents the world's most valuable generative art algorithm",
    "This former UCLA film student founded two of the top NFT projects. Take shot from your show, bro.",
    "I just buy bitcoins. I don't buy shitcoins. Just to get no coins. We the XXXXXX boys.",
    "This meme started after someone typed in banger accidentally in a group chat",
    "The web3 sleuth that is a scammer’s worst nightmare.",
    "“ₕₑaₕ wₐₜ dₒ dₐ ₛₑᵣᵢᵤₛ ₑₙᵥₑₛₜᵤᵣₛ dₒ fᵤᵣ wᵤᵣₖ?”",
    "The moment SQUID rugged live",
    "Promo art vs the actual thing",
    "Lost $350K in the market and then set his apartment on fire.",
    "The mastermind of a 119,756 BTC hack",
    "The worst leveraged crypto trader of all time",
    "Flips apes for 00016 ETH profit and loves to gamble.",
    "This self-doxxed Texan degen loved feet and shilling defi projects. RIP",
    "Steady lads... deploying more capital",
    "May 22 celebrates the first item purchased in crypto history..",
    "Former CEO of a software company that owns billions in BTC",
    "Thiel Fellow who was the world's youngest crypto billionaire at age 27",
    "Refuses to do his job and give clear regulatory guidance",
    "Anti-crypto Senator who wants to regulate the market.",
    'Commited "one of the biggest financial frauds in history."',
    "Runs one of the most recognizable crypto channels on youtube",
    'Epic trolling of bitcoin maxis with “DOGECOIN TO THE MOON!" at a bitcoin conference.',
    "“Can devs do something?”",
    "“Told her I was taking pics of her, she said yes @BoredApeYC”",
    "When you get rugged but u still that motherfucker",
    "This Actor and producer was robbed of a bored ape after falling for a phishing scam",
    '"Hello - it’s me."Created the greatest crypto music video of all time.',
    "“Just a weirdo who took photos of myself everyday and turned it into NFTs”",
    "Crypto’s Comedian.",
  ];

  nameArray = [
    "Kevin Pixelmon",
    "Beanie",
    "Gary Vee",
    "Fidenza",
    "Frankdagodz",
    "Island Boys",
    "Banger",
    "Zachxbt",
    "Goblintown",
    "Squid Game Token",
    "Horse Drawing Meme",
    "Crypto Bitlord",
    "Razzlekhan",
    "Caroline Ellison",
    "Franklinisbored",
    "S🅰️v🅰️ge $crooge",
    "Do Kwon",
    "BTC Pizzas",
    "Michael Saylor",
    "Vitalik Buterin",
    "Gary Gensler",
    "Elizabeth Warren",
    "SBF",
    "Bitboy",
    "'DOGECOIN TO THE MOON!!!' bitcoin conference",
    "Hard Rock Nick",
    "Papasito",
    "Zeb Ross",
    "Seth Green",
    "Randi Zuckerberg",
    "Ghozali",
    "Kmoney",
  ];
  hintArray = [
    "Raised $70M from mint sales.",
    "Owner of a Beanie Punk.",
    "He runs VeeCon.",
    "A Tyler Hobbs creation.",
    "PFP of Rohun Vora",
    "twin brothers Kodiyakredd and Flyysoulja",
    "Paper bag",
    "The On-chain sleuth.",
    "We’re going to *****town",
    "Owww! We went to zero! Eeeooowwww!",
    "Don’t mint without seeing the art.",
    "Shitcoin Lord",
    "Morgan the money launderer.",
    "SBF’s right hand.",
    "Lost 2000 ETH and left the space.",
    "QuickPack King.",
    "Indicted in US after Montenegro arrest",
    "10,000 BTC",
    "Buys Another $150 Million in Bitcoin",
    "All your Eth belongs to me.",
    "SEC goon",
    "Vowed to raise an 'anti-crypto' army",
    "I fucked up, and should have done better.",
    "Author or “Catching Up to Crypto.”",
    "Bitcoin Miami Conference 2021",
    "The Face of Cryptocurrency",
    "She has an onlyfans too",
    "#1 stepper",
    "Family Guy",
    "Mark’s sister.",
    "NFT selfies",
    "Makes funny videos about web3",
  ];
  showStartScreen();
};

const loadGameSoundA = () => {
  try {
    backgS = new Howl({
      src: ["assets/Spot/media/bcks.ogg", "assets/Spot/media/bcks.mp3"],
      autoplay: false,
      loop: true,
      volume: 0.2,
      onend: function () {
        //console.log('Finished!');
        //	sound.stop();
      },
    });
  } catch (err) {}
  rightS = new Howl({
    src: ["assets/Spot/media/right.ogg", "assets/Spot/media/right.mp3"],
  });
  wrongS = new Howl({
    src: ["assets/Spot/media/wrong.ogg", "assets/Spot/media/wrong.mp3"],
  });
  resS = new Howl({
    src: ["assets/Spot/media/res.ogg", "assets/Spot/media/res.mp3"],
  });
  transS = new Howl({
    src: ["assets/Spot/media/trans.ogg", "assets/Spot/media/trans.mp3"],
  });
};

// start screen
const showStartScreen = () => {
  ctiles = 0;
  // imgc = 0;
  gameState = "start";
  startCont = new window.createjs.Container();
  stage.addChild(startCont);
  const bck = addBmp("GEndscr", 0, 0, false);
  startCont.addChild(bck);
  timeT = new window.createjs.Text("N/A", "30px Drip", "#000");
  startCont.addChild(timeT);
  timeT.x = 181;
  timeT.y = 55;
  const scobmp = addBmp("Gscoret", 55, 60, false);
  startCont.addChild(scobmp);
  scoreT = new window.createjs.Text("N/A", "30px Drip", "#000");
  startCont.addChild(scoreT);
  scoreT.x = 181;
  scoreT.y = 97;

  // const uName = new window.createjs.Text("USER", "40px Berlin", "#fff");
  // startCont.addChild(uName);
  // uName.x = 500;
  // uName.y = 54;
  // const uNameT = new window.createjs.Text(userName, "28px Berlin", "#1c2f49");
  // startCont.addChild(uNameT);
  // uNameT.x = 700;
  // uNameT.y = 59;
  const bck2 = addBmp("GMtitle", stWidth / 2, 300, true);
  startCont.addChild(bck2);

  const bck3 = addBmp("Gtitleq", stWidth / 2, 490, true);
  startCont.addChild(bck3);

  btStart = addBmp("GConnectbt", 0, 0, true);

  btStart.x = stWidth / 2;
  btStart.y = 550;
  startCont.addChild(btStart);
  btStart.addEventListener("click", gameIni);
  btStart.shadow = new window.createjs.Shadow("#000", 2, 2, 5);
  btStart.cursor = "pointer";

  const botmenu = addBmp("Gbotmenu", 0, 0, true);
  startCont.addChild(botmenu);
  botmenu.x = stWidth / 2;
  botmenu.y = 620;
  botmenu.mouseEnabled = false;
  if (isMob && level === 1) {
    mobMess = addBmp("GMessw", stWidth / 2, 230, true);
    startCont.addChild(mobMess);
    setTimeout(hideMobWinMess, 3000);
  }
  //getUserData();
  sbox = addBmp("GLeadtf", stWidth / 2, 70, true);
  startCont.addChild(sbox);
  sbox.cursor = "pointer";
  sbox.addEventListener("click", getTheLeaderBoard);
  // const backbt = addBmp("Gbackbt", 100, 590, true);
  // startCont.addChild(backbt);
  // startCont.cursor = "pointer";
  // backbt.shadow = new window.createjs.Shadow("#000", 1, 1, 3);
  // backbt.addEventListener("click", goBackToStart);
};

const getTheLeaderBoard = async (e) => {
  isLoading = true;
  startCont.removeAllChildren();
  const bck = addBmp("GEndscr", 0, 0, false);
  startCont.addChild(bck);
  const sbox1 = boxMakerb(120, 140, "#447d85");
  startCont.addChild(sbox1);
  sbox1.x = 188;
  const sbox2 = boxMakerb(230, 50, "#447d85");
  startCont.addChild(sbox2);
  sbox2.x = 570;
  sbox2.y = 65;
  await showLeadBoard();
};

const showLeadBoard = async () => {
  leadCont = await makeLeaderBoard();
  startCont.addChild(leadCont);
  toggleIsLeaderboardOpen(true);
  leadCont.x = stWidth / 2;
  leadCont.y = 50;
};

const hideMobWinMess = () => {
  startCont.removeChild(mobMess);
};

const gameIni = (e) => {
  sbox.removeEventListener("click", getTheLeaderBoard);
  e.target.removeEventListener("click", gameIni);
  if (!gamePause) {
    gamePause = true;
    setTimeout(showMainIni, 200);
  }
};
function showMainIni() {
  startCont.removeAllChildren();
  if (typeof backgS !== "undefined") backgS.play();
  startTheGame();
  topBack = new window.createjs.Container();
  stage.addChild(topBack);
  const mbck = addBmp("GBar", 0, 0, false);
  mbck.y = 0;
  topBack.addChild(mbck);
  var tcl = addBmp("GClck", 0, 10, false);
  tcl.x = 20;
  topBack.addChild(tcl);
  timeT = new window.createjs.Text("00:00", "28px Drip", "#000");
  topBack.addChild(timeT);
  timeT.x = 70;
  timeT.y = mbck.y + 15;

  levelT = new window.createjs.Text(`Level : ${level}`, "28px Drip", "#000");
  topBack.addChild(levelT);
  levelT.x = 310;
  levelT.y = mbck.y + 15;
  levelT.textAlign = "center";

  scoreT = new window.createjs.Text("Score: 0", "28px Drip", "#000");
  topBack.addChild(scoreT);
  scoreT.x = 1100;
  scoreT.y = mbck.y + 15;

  btMusic = new window.createjs.Container();
  const music = addBmp("Gmusic", 1340, 0, false);
  btMusic.addChild(music);
  const music1 = addBmp("Gmusico", 1340, 0, false);
  btMusic.addChild(music1);
  music1.alpha = 0;
  music1.name = "musicoff";
  btMusic.cursor = "pointer";
  btMusic.mouseChildren = false;
  btMusic.addEventListener("click", musicOnOff);
  stage.addChild(btMusic);
  btMusic.x = stWidth / -50;
  btMusic.y = 15;
  btMusic.shadow = new window.createjs.Shadow("#000", 2, 2, 5);
  /*
  upAr = addBmp("GArrow", stWidth / 2, 58, true);
  stage.addChild(upAr);
  upAr.cursor = "pointer";
  upAr.addEventListener("mousedown", naviGateScene);
  upAr.addEventListener("pressup", naviGateSceneb);
  upAr.name = "uparrow";
  downAr = addBmp("GArrow", stWidth / 2, stHeight - 87, true);
  stage.addChild(downAr);
  downAr.cursor = "pointer";
  downAr.scaleY = -1;
  downAr.name = "downarrow";
  downAr.addEventListener("mousedown", naviGateScene);
  downAr.addEventListener("pressup", naviGateSceneb);
  leftAr = addBmp("GArrow", 22, stHeight / 2, true);
  stage.addChild(leftAr);
  leftAr.cursor = "pointer";
  leftAr.rotation = -90;
  leftAr.name = "leftarrow";
  leftAr.addEventListener("mousedown", naviGateScene);
  leftAr.addEventListener("pressup", naviGateSceneb);
  leftAr.shadow = new window.createjs.Shadow("#000", 2, 2, 5);
  rightAr = addBmp("GArrow", stWidth - 22, stHeight / 2, true);
  stage.addChild(rightAr);
  rightAr.cursor = "pointer";
  rightAr.rotation = 90;
  rightAr.name = "rightarrow";
  rightAr.addEventListener("mousedown", naviGateScene);
  rightAr.addEventListener("pressup", naviGateSceneb);
  rightAr.shadow = new window.createjs.Shadow("#000", 2, 2, 5);
  */
  makeTileWin();
}

const naviGateSceneb = (e) => {
  kname = "";
};

const startTheGame = () => {
  timeInter && clearInterval(timeInter);
  sbox = undefined;
  totalR = 0;
  canClick = true;
  oldItems = [];
  backScene = new window.createjs.Container();
  startCont.addChild(backScene);
  // const i = imgArray[level - 1]; // ??
  const gname = String(imgArray[imgc]);
  const bck = addBmp(gname, 0, -60, false);
  backScene.addChild(bck);
  //var bckt = addBmp("GBackt",0,0,false);
  //backScene.addChild(bckt);
  backScene.x = 0;
  backScene.y = 0;
  backScene.width = bck.image.width;
  backScene.height = bck.image.height;

  //backScene.scaleX = backScene.scaleY=0.76;

  const mbck = boxMakerb(stWidth, 109, "#fff");
  mbck.y = stHeight - 108;
  startCont.addChild(mbck);
  mbck.alpha = 0.8;
  mbck.mouseEnabled = false;
  if (gname == "GBack1") {
    corArr = [
      [356, 89, 475, 202],
      [129, 647, 251, 723],
      [1160, 409, 1256, 592],
      [523, 716, 586, 798],
      [799, 279, 871, 365],
      [0, 486, 100, 676],
      [816, 436, 876, 532],
      [828, 491, 875, 636],
      [257, 576, 324, 735],
      [621, 410, 794, 577],
      [325, 494, 355, 555],
    ];
    questArray = [2, 3, 4, 7, 8, 15, 18, 21, 25, 26, 31];
  }
  if (gname == "GBack2") {
    corArr = [
      [552, 417, 677, 529],
      [783, 656, 881, 714],
      [906, 409, 1004, 520],
      [1220, 567, 1270, 680],
      [22, 139, 94, 226],
      [1258, 173, 1358, 341],
      [893, 682, 959, 787],
      [769, 442, 820, 573],
      [1035, 387, 1108, 550],
      [222, 375, 393, 546],
      [596, 564, 648, 629],
    ];
    questArray = [2, 3, 4, 7, 8, 15, 18, 21, 25, 26, 31];
  }
  if (gname == "GBack3") {
    corArr = [
      [321, 335, 444, 451],
      [1159, 760, 1300, 796],
      [0, 523, 99, 654],
      [765, 419, 816, 535],
      [907, 444, 977, 526],
      [775, 70, 875, 240],
      [911, 682, 960, 791],
      [569, 429, 622, 588],
      [280, 496, 352, 650],
      [1011, 276, 1182, 470],
      [118, 624, 166, 691],
    ];
    questArray = [2, 3, 4, 7, 8, 15, 18, 21, 25, 26, 31];
  }
  if (gname == "GBack4") {
    corArr = [
      [14, 463, 89, 577],
      [576, 214, 830, 467],
      [401, 65, 544, 212],
      [1183, 314, 1343, 633],
      [835, 554, 907, 656],
      [840, 315, 945, 454],
      [965, 427, 1115, 489],
      [632, 511, 720, 673],
      [1000, 0, 1185, 128],
      [140, 0, 287, 128],
    ];
    questArray = [0, 1, 5, 13, 18, 19, 20, 22, 28, 30];
  }
  if (gname == "GBack5") {
    corArr = [
      [633, 563, 725, 674],
      [416, 40, 542, 173],
      [806, 84, 936, 214],
      [57, 312, 192, 503],
      [214, 328, 316, 413],
      [491, 307, 620, 442],
      [1201, 682, 1358, 742],
      [29, 450, 87, 535],
      [148, 0, 262, 121],
      [1006, 0, 1209, 130],
    ];
    questArray = [0, 1, 5, 13, 18, 19, 20, 22, 28, 30];
  }
  if (gname == "GBack6") {
    corArr = [
      [498, 328, 582, 438],
      [137, 0, 285, 170],
      [1185, 337, 1299, 468],
      [40, 337, 196, 513],
      [214, 329, 315, 421],
      [821, 158, 947, 301],
      [1200, 679, 1356, 742],
      [795, 677, 862, 793],
      [402, 44, 535, 176],
      [1000, 0, 1209, 130],
    ];
    questArray = [0, 1, 5, 13, 18, 19, 20, 22, 28, 30];
  }
  if (gname == "GBack7") {
    corArr = [
      [1009, 310, 1053, 459],
      [751, 514, 819, 543],
      [261, 498, 306, 526],
      [214, 755, 305, 798],
      [706, 527, 783, 696],
      [71, 616, 146, 648],
      [1117, 285, 1291, 542],
      [624, 339, 709, 481],
      [902, 259, 964, 396],
      [43, 290, 262, 474],
      [287, 310, 384, 432],
    ];

    questArray = [2, 6, 9, 10, 12, 17, 23, 24, 27, 29, 31];
  }
  if (gname == "GBack8") {
    corArr = [
      [285, 271, 382, 392],
      [864, 763, 953, 797],
      [1261, 667, 1311, 698],
      [888, 625, 940, 658],
      [685, 489, 785, 666],
      [1010, 485, 1083, 633],
      [1199, 612, 1284, 646],
      [1118, 250, 1283, 488],
      [446, 357, 491, 458],
      [510, 379, 566, 535],
      [40, 251, 260, 439],
      [996, 276, 1049, 420],
    ];

    questArray = [2, 6, 9, 10, 11, 12, 17, 23, 24, 27, 29, 31];
  }
  if (gname == "GBack9") {
    corArr = [
      [284, 262, 378, 379],
      [673, 692, 761, 726],
      [1260, 657, 1307, 689],
      [802, 405, 838, 454],
      [433, 311, 513, 423],
      [462, 482, 529, 647],
      [833, 737, 920, 764],
      [42, 231, 272, 428],
      [851, 282, 902, 383],
      [578, 467, 661, 636],
      [1135, 246, 1254, 480],
      [998, 268, 1048, 410],
    ];

    questArray = [2, 6, 9, 10, 11, 12, 17, 23, 24, 27, 29, 31];
  }
  //alert(corArr[19]);
  //getGameTotal();
  // stage.addEventListener("mousedown", takeFirstCor);
  //backScene.mouseChildren = false;
  // stage.addEventListener("pressmove", moveTheImageV);
  backScene.cursor = "pointer";
  if (isMob) {
    //   stage.addEventListener("pressmove", moveTheImageV);
    //   stage.addEventListener("mousedown", takeFirstCor);
    //   backScene.cursor = "pointer";
  }
  //backScene.addEventListener("pressup", getCor);

  const qstr = `${currentQ + 1}. Find the following object`;

  qtext = new window.createjs.Text(qstr, "800 24px OpenS", "#000");
  startCont.addChild(qtext);
  qtext.x = 10;
  qtext.lineWidth = 1220;
  qtext.lineHeight = 36;

  qtext.y = mbck.y + 45;
  // let shadow = new window.createjs.Shadow("#000", 2, 2, 5);
  loadTarget();

  isWrong = false;
  messWin = new window.createjs.Container();
  const sbox1 = makeRoundBtS("#b5e5ff", "#b5e5ff", 500, 440, 20, 1);
  messWin.addChild(sbox1);
  sbox1.alpha = 0.9;
  /*right answer prompt*/
  const ufom = addBmp("Gufom", 250, 80, true);
  messWin.addChild(ufom);
  ufom.name = "ufomain";
  ufom.alpha = 0;
  const pcircle = makeCircle(70, "#808080", "#fff", 7);
  messWin.addChild(pcircle);
  pcircle.x = 250;
  pcircle.y = 230;
  pcircle.name = "pcircle";
  pcircle.alpha = 0;
  mcircle = makeCircle(68, "#808080", "#000", 1);
  mcircle.x = 250;
  mcircle.y = 230;
  mcircle.name = "mcircle";
  // mcircle.alpha = 0;
  const sboxc = makeRoundBtS("#808080", "#fff", 420, 80, 10, 4);
  messWin.addChild(sboxc);
  sboxc.regX = 210;
  sboxc.x = 250;
  sboxc.y = 310;
  sboxc.name = "textholder";
  sboxc.alpha = 0;
  /*wrong answer prompt*/
  const ufow = addBmp("Gwufo", 250, 200, true);
  messWin.addChild(ufow);
  ufow.name = "ufowrong";
  ufow.alpha = 0;
  const msT = new window.createjs.Text("", "26px OpenS", "#1c2f49");
  messWin.addChild(msT);
  msT.textAlign = "center";
  msT.x = 250;
  msT.y = 30;
  msT.lineWidth = 410;
  msT.name = "messT";
  if (level == 1) {
    msT.text =
      "Fren Find\nHow degen are you?\n\nAnswer the questions to find your “frenz” and level up your score. Search around and click on an object if you know the answer.\n\nAre you degen enough to make the allowlist?";
  }
  const msTb = new window.createjs.Text("", "26px Drip", "#1c2f49");
  messWin.addChild(msTb);
  msTb.textAlign = "center";
  msTb.x = 250;
  msTb.y = sboxc.y + 21;
  msTb.lineWidth = 370;
  msTb.name = String("messTb");
  msTb.alpha = 0;
  startCont.addChild(messWin);
  messWin.regX = 250;
  messWin.regY = 200;
  messWin.x = stWidth / 2;
  messWin.y = 300;
  messWin.alpha = 0;
  if (level == 1) {
    messWin.alpha = 1;
    const obt = addBmp("GBtok", 250, 400, true);
    messWin.addChild(obt);
    obt.addEventListener("click", hideInfoIns);
    obt.cursor = "pointer";
  }
  isHint = true;
  timeInter = setInterval(trackTime, 1000);
  hintWin = new window.createjs.Container();
  var hbc = addBmp("GHintb", 0, 0, false);
  hintWin.addChild(hbc);
  const msT1 = new window.createjs.Text("", "22px OpenS", "#1c2f49");
  hintWin.addChild(msT1);
  // msT1.textAlign = "center";
  msT1.x = 20;
  msT1.y = 10;
  msT1.lineWidth = 360;
  msT1.name = "messT";
  startCont.addChild(hintWin);
  hintWin.alpha = 0;
  hintWin.x = 955;
  hintWin.y = stHeight - 244;
  var hintBt = addBmp("GHint", 0, stHeight - 80, false);
  hintBt.x = stWidth - 60;
  startCont.addChild(hintBt);
  hintBt.cursor = "pointer";
  hintBt.addEventListener("click", showTheHints);
};

const hideInfoIns = (e) => {
  e.target.removeEventListener("click", hideInfoIns);
  messWin.removeChild(e.target);
  const msT = messWin.getChildByName("messT");
  msT.text = "";
  messWin.alpha = 0;
  gamePause = false;
  backScene.addEventListener("click", getCor);
};

const musicOnOff = (e) => {
  btMusic.removeEventListener("click", musicOnOff);
  setTimeout(mtoggleMain, 500);
};
const mtoggleMain = () => {
  isMusic = !isMusic;
  const moff = btMusic.getChildByName("musicoff");
  if (isMusic) {
    moff.alpha = 0;
    if (typeof backgS !== "undefined") backgS.play();
  } else {
    if (typeof backgS !== "undefined") backgS.stop();
    moff.alpha = 1;
    if (typeof wrongS !== "undefined") wrongS.stop();
    if (typeof rightS !== "undefined") rightS.stop();
    if (typeof resS !== "undefined") resS.stop();
    if (typeof transS !== "undefined") transS.stop();
  }
  btMusic.addEventListener("click", musicOnOff);
};

const showTheHints = (e) => {
  if (isHint) {
    isHint = false;
    hintWin.alpha = 1;
    const mst = hintWin.getChildByName("messT");
    mst.text = String(currentHint);
    setTimeout(hideTheHint, 3000);
  }
};

const hideTheHint = () => {
  score -= 50;
  isHint = true;
  scoreT.text = String("Score: " + score);
  const mst = hintWin.getChildByName("messT");
  mst.text = "";
  hintWin.alpha = 0;
};

const loadTarget = () => {
  let isD = false;
  isHint = true;
  let s;
  while (!isD) {
    s = Math.floor(Math.random() * questArray.length);

    if (oldItems.indexOf(s) === -1) {
      oldItems.push(s);
      isD = true;
    }
  }
  // let tstr = String(questArray[s]);
  let i = parseInt(questArray[s]);
  currentItem = {};
  let cor = corArr[s];
  currentItem.corA = cor;
  currentItem.index = i + 1;
  //var gname = String("Gitem"+(s+1));
  let qstr = `${currentQ + 1}. ${realArray[i]}`;
  qtext.text = qstr;
  currentHint = String(hintArray[i]);
};

const showFoundItem = (fname) => {
  const sname = `itemf${ctiles}`;
  const tile = tileCont.getChildByName(sname);
  const slit = tile.getChildByName("foundtc");
  slit.alpha = 1;
  const img = addBmp(fname, 0, 0, true);
  tile.addChild(img);
  img.x = 23;
  img.y = 25;
  img.name = String("timage");
  img.scaleX = img.scaleY = 0.5;
};

function resetTilesFound() {
  for (var i = 0; i < TTQ; i++) {
    var sname = String("itemf" + i);
    var tile = tileCont.getChildByName(sname);
    var slit = tile.getChildByName("foundtc");
    slit.alpha = 0;
    var smg = tile.getChildByName("timage");
    if (smg !== null) {
      tile.removeChild(smg);
    }
  }
}

const getNextQues = () => {
  if (isWrong) {
    score -= 500;
    total_sec -= 30;
    scoreT.text = String("Score: " + score);
  }
  isWrong = false;
  gamePause = false;
  canClick = true;
  resetMessWin();
  messWin.alpha = 0;
  if (currentQ < questArray.length - 1) {
    currentQ++;
    startCont.removeChild(currentItem);
    loadTarget();
  }
};

const showNextLevel = () => {
  clearInterval(timeInter);
  if (isMusic) {
    if (typeof transS !== "undefined") transS.play();
  }
  totalR = 0;
  ctiles = 0;
  messWin.alpha = 0;
  isWrong = false;
  var sbck = boxMakerb(stWidth, stHeight, "#000");
  startCont.addChild(sbck);
  sbck.name = String("lback");
  sbck.alpha = 0.7;
  var lbck = addBmp("Glcomp", stWidth / 2, 300, true);
  startCont.addChild(lbck);
  lbck.name = String("lback2");
  var ldtext = new window.createjs.Text("LEVEL COMPLETED", "40px Drip", "#000");
  startCont.addChild(ldtext);
  ldtext.x = lbck.x;
  ldtext.y = lbck.y - 110;
  ldtext.textAlign = "center";
  ldtext.name = String("lmesst");
  if (level >= TTL) {
    ldtext.text = String(
      "GOOD JOB !\nYou completed all of\nthe levels before\ntimer ran out"
    );
    setTimeout(getNextLevel, 3000);
  } else {
    var btNext = addBmp("Gbtnext", stWidth / 2, 350, true);
    startCont.addChild(btNext);
    btNext.name = String("btnext");
    btNext.isOn = true;
    btNext.cursor = "pointer";
    btNext.addEventListener("click", getNextLevela);
  }
  stage.removeEventListener("pressmove", moveTheImageV);
  stage.removeEventListener("mousedown", takeFirstCor);
  backScene.removeEventListener("click", getCor);
};

function getNextLevela(e) {
  var btNext = e.target;
  if (btNext.isOn) {
    btNext.isOn = false;
    var mst = messWin.getChildByName("messT");
    mst.text = String("");
    btNext.removeEventListener("click", getNextLevela);
    startCont.removeChild(btNext);
    setTimeout(getNextLevel, 200);
  }
}

function getNextLevel() {
  resetTilesFound();
  var lbck = startCont.getChildByName("lback");
  startCont.removeChild(lbck);
  var lbck = startCont.getChildByName("lback2");
  startCont.removeChild(lbck);
  var lbckt = startCont.getChildByName("lmesst");
  startCont.removeChild(lbckt);
  currentQ = 0;

  startCont.removeAllChildren();
  if (level < TTL) {
    level++;
    isHint = true;
    canClick = true;
    levelT.text = String("Level : " + level);
    //var i = imgArray[level-1];
    //decideLevelsItems(i);
    gamePause = false;
    imgc++;

    startTheGame();
    backScene.addEventListener("click", getCor);
  } else {
    gamePause = true;
    callGameOver("alldone");
  }
}
const checkFalseClick = (sX, sY) => {
  var isItem = true;
  // for (var i = 0; i < corArr.length; i++) {
  //   var tX1 = corArr[i][0];
  //   var tX2 = corArr[i][2];
  //   var tY1 = corArr[i][1];
  //   var tY2 = corArr[i][3];
  //   //alert("cc");
  //   if (sX - 5 >= tX1 && sX <= tX2 && sY - 5 >= tY1 && sY <= tY2) {
  //     isItem = true;
  //     break;
  //   }
  // }
  if (isItem) {
    if (isMusic) {
      if (typeof resS !== "undefined") resS.play();
    }
    isWrong = true;
    isItem = false;
    gamePause = true;
    setTimeout(playWrongRes, 500);
    messWin.alpha = 1;
    var ufom = messWin.getChildByName("ufowrong");
    ufom.alpha = 1;
    setTimeout(getNextQues, 2000);
  } else {
    canClick = true;
  }
  isItem = false;
};

function playWrongRes() {
  if (isMusic) {
    if (typeof wrongS !== "undefined") wrongS.play();
  }
}
const takeFirstCor = (e) => {
  prevX = stage.mouseX;
  prevY = stage.mouseY;
};

const naviGateScene = (e) => {
  const aname = e.target.name;
  kname = aname;
};

const moveWithArrows = (aname) => {
  if (aname === "rightarrow") {
    const nextX = backScene.x - 4;
    if (nextX > -backScene.width + stWidth) {
      backScene.x = nextX;
    }
  }
  if (aname === "leftarrow") {
    const nextX = backScene.x + 4;
    if (nextX < 0) {
      backScene.x = nextX;
    }
  }
  if (aname === "uparrow") {
    const nextY = backScene.y + 4;
    if (nextY < 0) {
      backScene.y = nextY;
    }
  }
  if (aname === "downarrow") {
    const nextY = backScene.y - 4;
    if (nextY > -backScene.height + stHeight) {
      backScene.y = nextY;
    }
  }
};

const moveTheImageV = (e) => {
  if (stage.mouseX >= prevX) {
    const nextX = backScene.x + 10;
    if (nextX < 0) {
      backScene.x = nextX;
    }
  }
  if (stage.mouseX < prevX) {
    const nextX = backScene.x - 10;
    if (nextX > -backScene.width + stWidth) {
      backScene.x = nextX;
    }
  }
  if (stage.mouseY >= prevY) {
    const nextY = backScene.y + 10;
    if (nextY < 10) {
      backScene.y = nextY;
    }
  }
  if (stage.mouseY < prevY) {
    const nextY = backScene.y - 10;
    if (nextY > -backScene.height + stHeight) {
      backScene.y = nextY;
    }
  }
};

const trackTime = () => {
  if (!gamePause) {
    msec++;
    sec++;
    let sect;
    if (msec >= 60) {
      msec = 0;
      min++;
    }
    const sleft = total_sec - sec;
    min = parseInt(sleft / 60);
    var dsec = parseInt(sleft % 60);
    if (min < 10) {
      sect = `0${min}m ${dsec}s`;
    } else {
      sect = `${min}m ${dsec}s`;
    }
    const tstr = sect;
    timeT.text = `${tstr}`;
  }
  if (sec >= total_sec) {
    clearInterval(timeInter);
    gamePause = true;
    timeT.text = "00:00";
    callGameOver("timesup");
    sec = 0;
  }
};

const callGameOver = (gstr) => {
  console.log(gstr);
  if (gameState !== "end") {
    gameState = "end";
    kname = "";
    clearInterval(timeInter);
    stage.removeChild(topBack);
    // stage.removeChild(upAr);
    // stage.removeChild(downAr);
    // stage.removeChild(leftAr);
    // stage.removeChild(rightAr);
    stage.removeChild(tileCont);
    qtext.text = "";
    if (total_right >= 10 && total_right < 20) {
      score += 2000;
    }
    if (total_right >= 20 && total_right < 30) {
      score += 5000;
    }
    if (total_right >= 30 && total_right < 40) {
      score += 7500;
    }
    if (!isWrong) {
      score += 15000;
    }
    let secleft = total_sec - sec;
    score += secleft * 100;
    const rsec = parseInt(sec % 60);
    const smin = parseInt(sec / 60);
    const tstr = `${smin} : ${rsec}`;
    sec = 0;
    msec = 0;
    startCont.removeAllChildren();
    const endScr = addBmp("GEndscr", 0, 0, false);
    startCont.addChild(endScr);
    sbox2 = addBmp("GLeadtf", stWidth - 100, 40, true);
    sbox2.cursor = "pointer";
    sbox2.addEventListener("click", getTheLeaderBoard);
    const bck = addBmp("Gtscore", stWidth / 2, 260, true);
    startCont.addChild(bck);
    const botmenu = addBmp("Gbotmenu", 0, 0, true);
    startCont.addChild(botmenu);
    botmenu.x = stWidth / 2;
    botmenu.y = 600;
    const mstr = score;
    var ktext = new window.createjs.Text(mstr, "110px Drip", "#00e0da");
    startCont.addChild(ktext);
    ktext.textAlign = "center";
    ktext.x = stWidth / 2;
    ktext.y = 320;
    ktext.shadow = new window.createjs.Shadow("#000", 2, 2, 5);
    sendData(score, totalFounds, rsec);
    btStart = addBmp("Gbtagain", 0, 0, true);

    btStart.x = stWidth / 2;
    btStart.y = 530;
    startCont.addChild(btStart);
    btStart.addEventListener("click", playAgain);

    btStart.cursor = "pointer";
    btStart.shadow = new window.createjs.Shadow("#000", 2, 2, 5);

    var sbox = makeRoundBtS("#fff", "#fff", 130, 40, 20, 1);
    sbox.x = 430;
    startCont.addChild(sbox);
    sbox.y = 127;
    sbox.alpha = 0.7;
    var ttext = new window.createjs.Text(tstr, "30px Drip", "#000");
    startCont.addChild(ttext);
    ttext.x = 461;
    ttext.y = 130;
    var sbox = makeRoundBtS("#fff", "#fff", 100, 40, 20, 1);
    sbox.x = 950;
    startCont.addChild(sbox);
    sbox.y = 127;
    sbox.alpha = 0.7;

    scoreT = new window.createjs.Text("#1", "30px Drip", "#000");
    startCont.addChild(scoreT);
    scoreT.textAlign = "center";
    scoreT.x = 1000;
    scoreT.y = 130;
    const tbc = new window.createjs.Text("TIME", "30px Drip", "#fff");
    startCont.addChild(tbc);
    tbc.x = 340;
    tbc.y = 130;
    const scoreTa = new window.createjs.Text("DEGEN", "30px Drip", "#fff");
    startCont.addChild(scoreTa);
    scoreTa.x = 840;
    scoreTa.y = 130;
    stage.removeChild(btMusic);
  }
};

const makeTileWin = () => {
  tileCont = new window.createjs.Container();
  tileCont.width = 0;
  for (let i = 0; i < TTQ; i++) {
    const tile = new window.createjs.Container();
    const nor = addBmp("GeBar", 0, 0, false);
    tile.addChild(nor);

    //   const mtextb = new window.createjs.Text(
    //     `${i + 1},
    //     Drip","#1c2f49"
    //   `
    //   );
    //   tile.addChild(mtextb);
    //   mtextb.textAlign = "center";
    //   mtextb.x = nor.image.width / 2;
    //   mtextb.y = nor.image.height / 2 - 30;

    const fnd = addBmp("GfBar", 0, 0, false);
    tile.addChild(fnd);
    fnd.name = String("foundtc");
    fnd.alpha = 0;

    tileCont.addChild(tile);
    tile.x = i * (nor.image.width + 5);
    tile.name = String("itemf" + i);
    tileCont.width += nor.image.width + 5;
  }
  tileCont.x = stWidth / 2 - 110;
  tileCont.inX = stWidth / 2 - 250;
  tileCont.y = 10;
  stage.addChild(tileCont);

  const swd = parseInt(stWidth - tileCont.inX);
  const smask = boxMakerb(swd, 100, "#ccc");
  smask.x = tileCont.x;
  smask.y = 10;
  tileCont.mask = smask;
  stage.setChildIndex(tileCont, stage.numChildren - 1);
};

const moveProWin = (e) => {
  const aname = e.target.name;
  let nextX;
  if (aname === "leftar") {
    nextX = tileCont.x + 20;
    if (nextX <= tileCont.inX) {
      tileCont.x = nextX;
    }
  }
  if (aname === "rightar") {
    nextX = tileCont.x - 20;
    if (nextX >= tileCont.inX - tileCont.width) {
      tileCont.x = nextX;
    }
  }
};

const updateGame = (e) => {
  if (proBarn !== null) {
    proBarn.updateCache();
  }
  stage.update();
};

// Create LeaderBoard Data Here
const makeLeaderBoard = async () => {
  let scores;
  try {
    scores = await (await instance.get("leaderboard")).data;
  } catch (err) {
    console.log(err.message);
    scores = {
      data: [
        {
          score: "Empty",
          found: "Empty",
          time: "Empty",
          user: {
            userName: "Empty",
            profileImage: null,
            twitterUserId: "Empty",
          },
        },
      ],
    };
  }

  const leadB = new window.createjs.Container();
  const backs = makeRoundBtS("#fff", "#fff", 1156, 620, 10, 1);
  leadB.addChild(backs);
  leadB.regX = 578;

  backs.alpha = 0.7;
  const titl = addBmp("GLeadt", 578, 40, true);
  leadB.addChild(titl);
  let ty = 0;
  const maskR = boxMakerb(1030, 486, "#ccc");
  maskR.x = 66;
  maskR.y = 80;
  const miniCont = new window.createjs.Container();
  miniCont.y = 80;
  miniCont.mask = maskR;
  leadB.addChild(miniCont);
  //leadB.addChild(maskR);
  // We can run loop on array of user and score data from here.
  scores.data.forEach((score, i) => {
    let token;
    if (i === 0) {
      token = makeToken(
        true,
        i + 1,
        score.score,
        score.found,
        score.time,
        score.user.userName,
        true,
        score.user.profileImage
      );
    } else {
      token = makeToken(
        false,
        i + 1,
        score.score,
        score.found,
        score.time,
        score.user.userName,
        false,
        score.user.profileImage
      );
    }
    miniCont.addChild(token);
    token.x = 68;
    token.y = ty;
    ty += 70;
  });

  miniCont.height = ty;
  miniCont.name = "minicont";
  const supAr = addBmp("GArrow", 1122, 260, true);
  leadB.addChild(supAr);
  supAr.cursor = "pointer";
  supAr.scaleX = 0.5;
  supAr.name = "upSc";
  supAr.addEventListener("mousedown", naviGateScore);
  supAr.addEventListener("pressup", naviGateScoreb);

  const sdAr = addBmp("GArrow", 1122, 310, true);
  leadB.addChild(sdAr);
  sdAr.cursor = "pointer";
  sdAr.scaleX = 0.5;
  sdAr.scaleY = -1;
  sdAr.name = String("dwSc");
  sdAr.addEventListener("mousedown", naviGateScore);
  sdAr.addEventListener("pressup", naviGateScoreb);
  const backbt = addBmp("Gbackbt", 500, 590, true);
  leadB.addChild(backbt);
  leadB.cursor = "pointer";
  backbt.shadow = new window.createjs.Shadow("#000", 1, 1, 3);
  backbt.addEventListener("click", goBackToStart);
  isLoading = false;
  return leadB;
};

export const goBackToStart = () => {
  stage.removeAllChildren();
  toggleIsLeaderboardOpen(false);
  initGameWord();
};

export const stopMusic = () => {
  backgS?.stop();
};

const naviGateScore = (e) => {
  const miniCont = leadCont.getChildByName("minicont");
  const bname = e.target.name;
  if (bname === "dwSc") {
    if (miniCont.y > -(miniCont.height - 486 - 140)) {
      miniCont.y -= 70;
    }
  }
  if (bname === "upSc") {
    if (miniCont.y < 70) {
      miniCont.y += 70;
    }
  }
};

const naviGateScoreb = (e) => {};
const makeToken = (isT, i, iscore, ifound, itime, iemail, isY, img) => {
  const token = new window.createjs.Container();
  const tbacks = makeRoundBtS("#419095", "#419095", 1020, 60, 10, 1);
  token.addChild(tbacks);
  if (isT) {
    const cup = addBmp("GCup", 12, 12, false);
    token.addChild(cup);
  }
  const numt = new window.createjs.Text(String(i), "26px Drip", "#fff");
  token.addChild(numt);
  numt.x = 62;
  numt.y = 15;
  numt.textAlign = "center";
  if (isY) {
    numt.color = "#ffcb00";
  }
  const tbs = makeRoundBtS("#419095", "#fff", 90, 46, 10, 1);
  token.addChild(tbs);
  tbs.x = 120;
  tbs.y = 7;
  // const twit = makeCircle(29, "#419095", "#fff", 3);
  // token.addChild(twit);
  // twit.x = 110;
  // twit.y = 30;
  const imgElem = document.createElement("img");
  imgElem.setAttribute("src", img);
  const numtw = new window.createjs.Text(
    String("twitter\navatar"),
    "12px Berlin",
    "#000"
  );
  numtw.x = 95;
  numtw.y = 6;
  const badge = addBmp("GBadge", 164, 12, false);
  token.addChild(badge);
  const mstr2 = `Score: ${iscore}`;
  const scort = new window.createjs.Text(mstr2, "20px Drip", "#fff");
  token.addChild(scort);
  scort.x = 230;
  scort.y = 19;

  const mstr3 = `Found: ${ifound}`;
  const foundt = new window.createjs.Text(mstr3, "20px Drip", "#fff");
  token.addChild(foundt);
  foundt.x = 410;
  foundt.y = 19;

  const mstr4 = `Time: ${itime}`;
  const ttie2 = new window.createjs.Text(mstr4, "20px Drip", "#fff");
  token.addChild(ttie2);
  ttie2.x = 570;
  ttie2.y = 19;

  const mstr = `Username : ${iemail}`;
  const ttie = new window.createjs.Text(mstr, "20px Drip", "#fff");
  token.addChild(ttie);
  ttie.x = 720;
  ttie.y = 19;
  let scol;
  if (isY) {
    scol = "#ffcb00";
  } else {
    scol = "#00656c";
  }
  const cnor = makeCircle(8, scol, scol, 1);
  token.addChild(cnor);
  cnor.x = 995;
  cnor.y = 30;
  return token;
};

const resetMessWin = () => {
  const thold = messWin.getChildByName("textholder");
  thold.alpha = 0;
  const ufom = messWin.getChildByName("ufomain");
  ufom.alpha = 0;
  const ufow = messWin.getChildByName("ufowrong");
  ufow.alpha = 0;
  const pcirc = messWin.getChildByName("pcircle");
  pcirc.alpha = 0;
  const messt = messWin.getChildByName("messT");
  messt.text = "";
  const messtb = messWin.getChildByName("messTb");
  messtb.text = "";
  const img = messWin.getChildByName("fimage");
  if (img !== null) {
    messWin.removeChild(img);
  }
};

//save the score online
const sendData = (score, found, time) => {
  instance
    .post("leaderboard", {
      score,
      time,
      found,
    })
    .then(() => {
      console.info("score is saved to server");
    })
    .catch((error) => {
      console.error("score is not saved to server!");
      console.log(error);
    });
};
// getUserData();

// const getUserData = () => {
//   const resultObj = localStorage.getItem("scoreBoard");
//   showAllScoreWin(resultObj);
// };

// const showAllScoreWin = (resultObj) => {
//   let scoreStr = resultObj;
//   scoreStr = scoreStr.trim();
//   let scA = scoreStr.split("|");
//   let nArray = [];
//   for (let i = 0; i < scA.length; i++) {
//     const sstr = scA[i];
//     const dAr = sstr.split("~");
//     nArray.push(dAr);
//   }
//   nArray = nArray.sort((a, b) => a[1] - b[1]);
//   nArray.reverse();
//   let sRank = 0;
//   for (let i = 0; i < nArray.length; i++) {
//     const dAr = nArray[i];
//     const tname = dAr[0];
//     const tscore = dAr[1];
//     if (tname === userName && tscore === score) {
//       sRank = i + 1;
//     }
//   }

//   const rtext = new window.createjs.Text(`#${sRank}`, "30px Drip","#000");
//   startCont.addChild(rtext);
//   // debugger;
//   rtext.x = 191;
//   rtext.y = 106;
// };

//   const makeRect = (wd, ht, color) => {
//     const tile = new window.createjs.Shape();
//     tile.graphics.beginStroke(color);
//     tile.graphics.setStrokeStyle(4);
//     tile.graphics.drawRect(0, 0, wd, ht);
//     tile.graphics.endFill();
//     return tile;
//   }; // ??

//   const openFullscreen = (e) => {
//     const elem = canvasRef.current;
//     if (elem.requestFullscreen) {
//       elem.requestFullscreen();
//     } else if (elem.webkitRequestFullscreen) {
//       /* Safari */
//       elem.webkitRequestFullscreen();
//     } else if (elem.msRequestFullscreen) {
//       /* IE11 */
//       elem.msRequestFullscreen();
//     }
//   }; // ??

const playAgain = (e) => {
  e.target.removeEventListener("click", playAgain);
  initGameWord();
};

const boxMakerb = (wdt, h, color) => {
  const shape = new window.createjs.Shape();
  shape.graphics.beginFill(color).drawRect(0, 0, wdt, h);
  return shape;
};

//   const boxMaker = (wdt, h, color, scol) => {
//     const shape = new window.createjs.Shape();
//     shape.graphics.beginStroke(scol);
//     shape.graphics.setStrokeStyle(1);
//     shape.graphics.beginFill(color).drawRect(0, 0, wdt, h);

//     return shape;
//   };

const makeCircle = (rad, scol, fcol, strk) => {
  const g = new window.createjs.Graphics();
  g.setStrokeStyle(strk);
  g.beginStroke(scol);
  g.beginFill(fcol);
  g.drawCircle(0, 0, rad);
  const s = new window.createjs.Shape(g);
  return s;
};

// makes rounded windows
const makeRoundBtS = (sc1, sc2, wd, ht, corner, strk) => {
  const shape = new window.createjs.Shape();
  shape.graphics.beginStroke(sc1);
  shape.graphics.setStrokeStyle(strk);
  shape.graphics.beginFill(sc2).drawRoundRect(0, 0, wd, ht, corner);
  return shape;
};

//   const btmouseover = (e) => {
//     const snor = e.target.getChildByName("mout");
//     snor.alpha = 0;
//     const snor1 = e.target.getChildByName("mover");
//     snor1.alpha = 1;
//   };

//   const btmouseout = (e) => {
//     const snor = e.target.getChildByName("mout");
//     snor.alpha = 1;
//     const snor2 = e.target.getChildByName("mover");
//     snor2.alpha = 0;
//   };

// shuffles array or randomizes values in an array
const shuffle = (sArray) => {
  const tArray = [];
  let isD = false;
  while (!isD) {
    const s = Math.floor(Math.random() * sArray.length);
    const str = sArray[s];
    if (tArray.indexOf(str) === -1) {
      tArray.push(str);
    }
    if (tArray.length >= sArray.length) {
      isD = true;
    }
  }
  return tArray;
};

// tween the alpha value
//   const alphaTween = (target, t, wts) => {
//     window.createjs.Tween.get(target)
//       .wait(wts)
//       .to({ alpha: t }, 500, window.createjs.Ease.None);
//   }; // ??

//makes button based on image loaded

//   const makeBtImage = (Inor, Iover, isR) => {
//     const bCont = new window.createjs.Container();
//     const bag = addBmp(Inor, 0, 0, true);
//     bCont.addChild(bag);
//     bag.name = "mout";
//     const bag2 = addBmp(Iover, 0, 0, true);
//     bCont.addChild(bag2);
//     bag2.name = "mover";
//     bag2.alpha = 0;
//     bCont.cursor = "pointer";

//     if (isR) {
//       bCont.addEventListener("mouseover", btmouseover);
//       bCont.addEventListener("mouseout", btmouseout);
//     }
//     bCont.mouseChildren = false;

//     return bCont;
//   };

//progress bar

/* -------------------------------------------------------------------------------------------- */
/* ------------------------ makes the loading progress bar ------------------------------------ */
/* -------------------------------------------------------------------------------------------- */
const makeProBar = () => {
  proBarn = new window.createjs.Container();
  const proStrp = new window.createjs.Container();
  const pbase = new window.createjs.Bitmap(
    "assets/Spot/images/progressbar_base.png"
  );
  proStrp.addChild(pbase);

  let loading_text;

  loading_text = "LOADING";

  const stext = new window.createjs.Text(loading_text, "14px Arial", "#fff");
  stext.x = 30;
  stext.y = 16;
  proStrp.addChild(stext);

  const strip = new window.createjs.Bitmap(
    "assets/Spot/images/progressbar_stripes.png"
  );
  proBarn.x = 0;
  proBarn.y = 36;
  proBarn.addChild(strip);
  window.createjs.Tween.get(strip, { loop: true }).to(
    { x: 20 },
    200,
    window.createjs.Ease.None
  );

  const stmask = new window.createjs.Bitmap(
    "assets/Spot/images/progressbar_mask.png"
  );
  const maskFillter = new window.createjs.AlphaMaskFilter(stmask.image);
  proBarn.filters = [maskFillter];
  proBarn.cache(0, 0, 600, 350);
  proStrp.addChild(proBarn);

  const probarW = 343;
  const probarH = 85;
  proStrp.x = Math.floor(stage.canvas.width / 2 - probarW / 2);
  proStrp.y = Math.floor(stage.canvas.height / 2 - probarH / 2);
  proT = new window.createjs.Text("0", "400 12px Arial", "#FFFFFF");
  proStrp.addChild(proT);
  proT.x = Math.floor(probarW - 113);
  proT.y = Math.floor(50);
  stage.addChild(proStrp);
  return proStrp;
};
//when document Ready, resize and init game
//   docReady(function () {
//     reSizeHandler();
//     initGameWord();

//     //orientation change: auto scroll to game top
//     window.addEventListener("orientationchange", function () {
//       window.setTimeout(function () {
//         document.getElementById("mainb").scrollIntoView(true);
//       }, 350);
//     });
//   });

/////////////////////////////////////////////////////////

const getCor = (e) => {
  if (canClick) {
    const nX = parseInt(stage.mouseX);
    const nY = parseInt(stage.mouseY);
    // if (Math.abs(prevX - nX) <= 5 && Math.abs(prevY - nY) <= 5) {
    //   haveClicked = true;
    //   totalQuestions++;
    // }
    const sX = parseInt(Math.abs(backScene.x) + stage.mouseX);
    const sY = parseInt(Math.abs(backScene.y) + stage.mouseY);
    const tX1 = currentItem.corA[0];
    const tX2 = currentItem.corA[2];
    const tY1 = currentItem.corA[1];
    const tY2 = currentItem.corA[3];
    //alert("cc");
    if (sX - 5 >= tX1 && sX <= tX2 && sY - 5 >= tY1 && sY <= tY2) {
      canClick = false;
      if (isMusic) {
        if (typeof resS !== "undefined") resS.play();
      }
      score += 1000;
      totalFounds++;
      total_right += 1;
      scoreT.text = String("Score: " + score);
      gamePause = true;
      messWin.alpha = 1;
      const s = currentItem.index;
      const tname = String(nameArray[s - 1]);
      const messtb = messWin.getChildByName("messTb");
      messtb.alpha = 1;
      messtb.text = String(tname);
      const sname = String("Gitem" + s);
      showFoundItem(sname);
      messWin.alpha = 1;
      const thold = messWin.getChildByName("textholder");
      thold.alpha = 1;
      if (messtb.getBounds().width > 360) {
        messtb.y = thold.y + 13;
      } else {
        messtb.y = thold.y + 22;
      }
      const ufom = messWin.getChildByName("ufomain");
      ufom.alpha = 1;
      const pcirc = messWin.getChildByName("pcircle");
      pcirc.alpha = 1;
      const img = addBmp(sname, 0, 0, true);
      messWin.addChild(img);
      img.x = pcirc.x;
      img.y = pcirc.y;
      img.scaleX = img.scaleY = 1.2;
      img.name = String("fimage");
      img.mask = mcircle;
      totalR++;
      ctiles++;
      if (totalR >= TTQ) {
        setTimeout(rightMusicP, 500);
        setTimeout(showNextLevel, 2000);
      } else {
        setTimeout(rightMusicP, 500);
        setTimeout(getNextQues, 2000);
      }
      return;
    } else {
      canClick = false;
      checkFalseClick(sX, sY);
    }
  }
};

function rightMusicP() {
  if (isMusic) {
    if (typeof rightS !== "undefined") rightS.play();
  }
}
export const setCanvas = (canvasRef) => {
  canvas = canvasRef;
};

export const initGameWord = () => {
  resetState();
  stage = new window.createjs.Stage(canvas);
  optimizeForTouchAndScreens();
  stage.enableMouseOver(10);
  stWidth = stage.canvas.width;
  stHeight = stage.canvas.height;
  window.createjs.Ticker.setFPS(60);
  window.createjs.Ticker.addEventListener("tick", updateGame);
  stage.snapToPixelEnabled = true;

  proBar = makeProBar();
  stage.addChild(proBar);
  loadGameAssets();
  loadGameSoundA();
};

const loadGameAssets = () => {
  const manifest = [
    { src: "assets/Spot/images/a1.png", id: "GBack1" },
    { src: "assets/Spot/images/a2.png", id: "GBack2" },
    { src: "assets/Spot/images/a3.png", id: "GBack3" },
    { src: "assets/Spot/images/b1.png", id: "GBack4" },
    { src: "assets/Spot/images/b2.png", id: "GBack5" },
    { src: "assets/Spot/images/b3.png", id: "GBack6" },
    { src: "assets/Spot/images/c1.png", id: "GBack7" },
    { src: "assets/Spot/images/clock.png", id: "GClck" },
    { src: "assets/Spot/images/hintwin.png", id: "GHintb" },
    { src: "assets/Spot/images/c2.png", id: "GBack8" },
    { src: "assets/Spot/images/c3.png", id: "GBack9" },
    { src: "assets/Spot/images/cup.png", id: "GCup" },
    { src: "assets/Spot/images/badge.png", id: "GBadge" },
    { src: "assets/Spot/images/leadt.png", id: "GLeadt" },
    { src: "assets/Spot/images/okbt.png", id: "GBtok" },
    { src: "assets/Spot/images/endscr.png", id: "GEndscr" },
    { src: "assets/Spot/images/leadtf.png", id: "GLeadtf" },
    { src: "assets/Spot/images/nextbt.png", id: "Gbtnext" },
    { src: "assets/Spot/images/lcomp.png", id: "Glcomp" },
    { src: "assets/Spot/images/oscoret.png", id: "Gscoret" },
    { src: "assets/Spot/images/backbt.png", id: "Gbackbt" },
    { src: "assets/Spot/images/btagain.png", id: "Gbtagain" },
    { src: "assets/Spot/images/hint.png", id: "GHint" },
    { src: "assets/Spot/images/mMess.png", id: "GMessw" },
    { src: "assets/Spot/images/btstart.png", id: "GConnectbt" },
    { src: "assets/Spot/images/mtitle.png", id: "GMtitle" },
    { src: "assets/Spot/images/titleq.png", id: "Gtitleq" },
    { src: "assets/Spot/images/tscore.png", id: "Gtscore" },
    { src: "assets/Spot/images/bar.png", id: "GBar" },
    { src: "assets/Spot/images/arrow.png", id: "GArrow" },
    { src: "assets/Spot/images/botmenu.png", id: "Gbotmenu" },
    { src: "assets/Spot/images/bar2.png", id: "GBar2" },
    { src: "assets/Spot/images/fbar.png", id: "GfBar" },
    { src: "assets/Spot/images/ebar.png", id: "GeBar" },
    { src: "assets/Spot/images/ufom.png", id: "Gufom" },
    { src: "assets/Spot/images/wrongufo.png", id: "Gwufo" },
    { src: "assets/Spot/images/cloudpop.png", id: "Gcpop" },
    { src: "assets/Spot/images/dragb.png", id: "Gdragb" },
    { src: "assets/Spot/images/item1s.png", id: "Gitem1" },
    { src: "assets/Spot/images/item2s.png", id: "Gitem2" },
    { src: "assets/Spot/images/item3s.png", id: "Gitem3" },
    { src: "assets/Spot/images/item4s.png", id: "Gitem4" },
    { src: "assets/Spot/images/item5s.png", id: "Gitem5" },
    { src: "assets/Spot/images/item6s.png", id: "Gitem6" },
    { src: "assets/Spot/images/item7s.png", id: "Gitem7" },
    { src: "assets/Spot/images/item8s.png", id: "Gitem8" },
    { src: "assets/Spot/images/item9s.png", id: "Gitem9" },
    { src: "assets/Spot/images/item10s.png", id: "Gitem10" },
    { src: "assets/Spot/images/item11s.png", id: "Gitem11" },
    { src: "assets/Spot/images/item12s.png", id: "Gitem12" },
    { src: "assets/Spot/images/item13s.png", id: "Gitem13" },
    { src: "assets/Spot/images/item14s.png", id: "Gitem14" },
    { src: "assets/Spot/images/item15s.png", id: "Gitem15" },
    { src: "assets/Spot/images/item16s.png", id: "Gitem16" },
    { src: "assets/Spot/images/item17s.png", id: "Gitem17" },
    { src: "assets/Spot/images/item18s.png", id: "Gitem18" },
    { src: "assets/Spot/images/item19s.png", id: "Gitem19" },
    { src: "assets/Spot/images/item20s.png", id: "Gitem20" },
    { src: "assets/Spot/images/item21s.png", id: "Gitem21" },
    { src: "assets/Spot/images/item22s.png", id: "Gitem22" },
    { src: "assets/Spot/images/item23s.png", id: "Gitem23" },
    { src: "assets/Spot/images/item24s.png", id: "Gitem24" },
    { src: "assets/Spot/images/item25s.png", id: "Gitem25" },
    { src: "assets/Spot/images/item26s.png", id: "Gitem26" },
    { src: "assets/Spot/images/item27s.png", id: "Gitem27" },
    { src: "assets/Spot/images/item28s.png", id: "Gitem28" },
    { src: "assets/Spot/images/item29s.png", id: "Gitem29" },
    { src: "assets/Spot/images/item30s.png", id: "Gitem30" },
    { src: "assets/Spot/images/item31s.png", id: "Gitem31" },
    { src: "assets/Spot/images/item32s.png", id: "Gitem32" },
    { src: "assets/Spot/images/musicon.png", id: "Gmusic" },
    { src: "assets/Spot/images/musicoff.png", id: "Gmusico" },
  ];
  proT = new window.createjs.Text("0", "18px Arial", "#000");
  stage.addChild(proT);
  proT.x = stWidth / 2;
  proT.textAlign = "center";
  proT.y = 210;
  loader = new window.createjs.LoadQueue(false);
  loader.installPlugin(window.createjs.Sound);
  window.createjs.Sound.initializeDefaultPlugins();
  window.createjs.Sound.alternateExtensions = ["mp3", "ogg"];
  loader.addEventListener("progress", handleProgress);
  loader.addEventListener("complete", handleComplete);
  loader.loadManifest(manifest, true);
  if (window.createjs.Touch.isSupported() === true)
    stage.addEventListener("pressmove", PreventScrollOnTouchDevice);
};
