import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useContext } from "react";
import AuthContext2 from "../Context/AuthContext";

const Loader = (props) => {
  const { isLoading, isFetching } = useContext(AuthContext2);
  return isLoading || isFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  ) : (
    props.children
  );
};

export default Loader;
