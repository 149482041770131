import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import * as React from "react";
import TweetCard from "react-tweet-card";
import "./PreviewCard.css";

const PreviewCard = ({
  setOpen,
  open,
  shareOnTwitter,
  goBackToStart,
  userName,
  triggerAlert,
}) => {
  const [isSharing, setIsSharing] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const shareHandler = async () => {
    try {
      setIsSharing(true);
      const data = await shareOnTwitter();
      if (data.error) {
        throw data.error;
      }
      triggerAlert(
        "success",
        `Tweet shared successfully! Remaining tries: ${data.data}`
      );
    } catch (err) {
      triggerAlert(
        "error",
        `Something went wrong while sharing Tweet! try again after some time.`
      );
      console.log(err);
    }
    setOpen(false);
    goBackToStart();
    setIsSharing(false);
  };

  const card = (
    <Card
      className="modal-bg"
      sx={{
        minWidth: "400px",
        padding: "0.5rem",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CardContent
        sx={{
          p: "1rem",
        }}
      >
        <Typography>- You are about to share your score in Twitter.</Typography>
        <TweetCard
          style={{
            padding: "0.7rem",
            margin: "1rem 0",
            border: "1px solid #2AA3EF",
          }}
          author={{
            name: "Vikalp",
            username: userName,
            image:
              "https://pbs.twimg.com/profile_images/1626519218241024002/GbtZBQbE_normal.png",
          }}
          tweet="Hey I played this game and my score is 100, you should also play this game."
          time={new Date()}
          source="This is Demo Tweet"
          fitInsideContainer
        />
        <Typography>- Above is preview of tweet.</Typography>
        <Typography>
          - Click on 'Share Now' to share your score and get 5 chance for
          playing game.
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          margin: "0 0 1rem 1rem",
          padding: "0",
        }}
      >
        <Button
          size="small"
          variant="contained"
          disabled={isSharing}
          onClick={shareHandler}
          sx={{
            backgroundColor: "#2AA3EF",
          }}
        >
          {isSharing ? "Sharing..." : "Share Now"}
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        container={document.getElementById("mainb")}
        disablePortal
        children={card}
        aria-labelledby="preview-card"
        aria-describedby="share-tweet-preview-card"
      ></Modal>
    </div>
  );
};

export default PreviewCard;
