import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext2 from "../Context/AuthContext";
import Loader from "./Loader";

const ProtectedRoute = (props) => {
  const ctx = useContext(AuthContext2);
  const { isLoggedIn } = ctx;

  return (
    <Loader>
      {isLoggedIn ? props.children : <Navigate to={"/"} replace />}
    </Loader>
  );
};

export default ProtectedRoute;
