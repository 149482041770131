import { Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import useAlert from "../../Utils/useAlert";
import {
  configureVars,
  goBackToStart,
  initGameWord,
  isLoading,
  setCanvas,
  shareOnTwitter,
  stopMusic,
  stopTicker,
} from "./GameEngine";
import PreviewCard from "./PreviewTweetCard";
import "./Spot.css";
import { reSizeHandler } from "./common";

const Spot = () => {
  const canvasRef = useRef();
  const mainb = useRef();
  const ctx = useContext(AuthContext);
  const { userId, userName, isLoggedIn, profileImage } = ctx;
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { AlertComponent, triggerAlert } = useAlert();

  useEffect(() => {
    //when document Ready, resize and init game
    if (isLoggedIn) {
      return window.docReady(function () {
        reSizeHandler();
        setCanvas(canvasRef.current);
        configureVars(userName, userId, profileImage, setIsLeaderboardOpen);
        initGameWord();
        window.addEventListener("resize", function () {
          reSizeHandler();
        });

        //orientation change: auto scroll to game top
        window.addEventListener("orientationchange", function () {
          window.setTimeout(function () {
            mainb.current.scrollIntoView(true);
          }, 350);
        });
      });
    }
    navigate("/");
    return () => {
      stopMusic();
      stopTicker();
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Spot Game - Node Aliens</title>
      </Helmet>
      <div id="mainb" ref={mainb}>
        <PreviewCard
          shareOnTwitter={shareOnTwitter}
          open={open}
          setOpen={setOpen}
          userName={userName}
          goBackToStart={goBackToStart}
          triggerAlert={triggerAlert}
        />
        <canvas id="gamew" width="1366" height="800" ref={canvasRef}></canvas>
        {isLeaderboardOpen && !isLoading && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              position: "absolute",
              bottom: "3.9%",
              left: "52.2%",
              width: "13%",
              height: "5.6%",
              fontSize: "1.4vw",
              borderRadius: "1vw",
            }}
          >
            Share
          </Button>
        )}
      </div>
      <AlertComponent />
      <div className="no-touch-action" id="NoTouchMoveDiv"></div>
    </React.Fragment>
  );
};

export default Spot;
