import NewPage from "../../Utils/NewPage";
import "./NotFound.css";

const NotFound = () => {
  return (
    <NewPage title="Invalid URL - Node Aliens">
      <h1>Page Not Found!</h1>
    </NewPage>
  );
};

export default NotFound;
