import { createContext } from "react";

const AuthContext2 = createContext({
  isLoggedIn: false,
  userId: "",
  profileImage: "",
  userName: "",
  walletAddress: "",
  activeWallet: "empty",
  hasMetaMask: false,
  isWalletConnected: false,
  isLoading: false,
  isFetching: true,
  fetchUserData: () => {},
  setWalletAddress: () => {},
  setActiveWallet: () => {},
  logout: () => {},
  setNoMetaMaskDialogueOpen: () => {},
  handleClose: () => {},
  connectWallet: () => {},
  allowAudio: true,
  toggleAudio: () => {},
  showDialog: () => {}
});

export default AuthContext2;
