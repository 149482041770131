import React from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import TEMPLE from "../../images/TEMPLE.jpeg";
import TEMPLEMOBILE from "../../images/TEMPLEMOBILE.jpeg";

function Temple() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Temple - Node Aliens">
      <div>
        <Loader>
          <div>
            <div>
              <img className="desktopView" src={TEMPLE} />
              <img className="mobileView" src={TEMPLEMOBILE} />
            </div>

            {/* {isLoggedIn &&
              activeWallet?.toLowerCase() !== walletAddress?.toLowerCase() && (
                <Button
                  className="metaMaskBtn"
                  variant="outlined"
                  startIcon={
                    <img
                      className="metamaskicon"
                      src={metaMask}
                      alt="connect-to-metamask"
                    />
                  }
                  onClick={connectWallet}
                >
                  Connect to Metamask Wallet
                </Button>
              )} */}
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Temple;
