import { default as React } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Utils/Loader";
import mobile_main from "../../images/mobile_main.gif";
import NewPage from "../../Utils/NewPage";
import "./home.css";

function Home() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, activeWallet, connectWallet } = ctx;

  return (
    <NewPage title="Home - Node Aliens" showSingInWithTwitterButton>
      <div>
        <Loader>
          <div>
            <div className="homeBg">
              <img className="mobileView" src={mobile_main} />
              {/* <Link className="aTag" to="/second" props={"audio"}></Link> */}
              <Link className="li" to="/second" props={"audio"}>
                ENTER
              </Link>
              {/* {isLoggedIn &&
                walletAddress?.toLowerCase() !==
                activeWallet?.toLowerCase() && (
                  <Button
                    className="metaMaskBtn"
                    variant="outlined"
                    startIcon={
                      <img
                        className="metamaskicon"
                        src={metaMask}
                        alt="connect-to-metamask"
                      />
                    }
                    onClick={connectWallet}
                  >
                    Connect to Metamask Wallet
                  </Button>
                )} */}
            </div>
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Home;
