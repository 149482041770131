import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NewPage from "../../Utils/NewPage";
import "./loginerr.css";

function Loginerr() {
  const navigate = useNavigate();
  const params = useSearchParams();
  const message = params[0].get("message");
  let title;
  switch (message) {
    case "USER_DENIED_ACCESS": {
      title = "User has denied access for the twitter profile while login!";
      break;
    }

    default: {
      title = "Something went wrong! please try again later!";
    }
  }
  return (
    <NewPage title="Error While Login - Node Aliens">
      <div className="loginerrSection">
        <h4>{title}</h4>
        <button onClick={() => navigate("/")}>Goto Home</button>
      </div>
    </NewPage>
  );
}

export default Loginerr;
