import { default as React } from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import MiddleMobile from "../../images/MiddleMobile.jpeg";
import "./middle.css";

function Middlepg() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, isLoggedIn, connectWallet, activeWallet } = ctx;

  return (
    <NewPage title="Middle - Node Aliens">
      <div>
        <Loader>
          <div>
            <div className="middleBg">
              {" "}
              <img className="mobileView" src={MiddleMobile} />
            </div>
            {/* {isLoggedIn &&
              walletAddress?.toLowerCase() !== activeWallet?.toLowerCase() && (
                <Button
                  className="metaMaskBtn"
                  variant="outlined"
                  startIcon={
                    <img
                      className="metamaskicon"
                      src={metaMask}
                      alt="connect-to-metamsk"
                    />
                  }
                  onClick={() => connectWallet()}
                >
                  Connect to Metamask Wallet
                </Button>
              )} */}
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Middlepg;
