import * as React from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import "./cs.css";

function ComingSoon() {
  return (
    <NewPage title="Aliens - Node Aliens">
      <div>
        <Loader>
          <div>
            <div className="csContainer">
              <iframe style={
                {
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  width: "100vw", 
                  height: "100vh"
                }
              } src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FoXZiWh7DWvPlvSe0ySgFiQ%2FNode-Aliens%3Ftype%3Ddesign%26node-id%3D7-2%26t%3DbB3BdeC9Kwxif2xv-1%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D7%253A2%26mode%3Ddesign" allowfullscreen></iframe>
            </div>
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default ComingSoon;
