import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useEffect, useState } from "react";
import instance from "../Utils/AxiosInstance";
import useAlert from "../Utils/useAlert";
import AuthContext2 from "./AuthContext";
import useDialog from "../Utils/useDialog";

const initialState = {
  userId: null,
  profileImage: null,
  userName: null,
  walletAddress: null,
  isLoggedIn: false,
  isFetching: true,
};

const AuthProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allowAudio, toggleAudio] = useState(true);
  const [userData, setUserData] = useState(initialState);
  const [NoMetaMaskDialogOpen, setNoMetaMaskDialogueOpen] = useState(false);
  const [dialogopen, setdialogOpen] = useState(false);
  const [isWalletConnected, setIsWalletConneted] = useState(false);
  const { AlertComponent, triggerAlert, handleClose } = useAlert();
  const { MUIDialog, showDialog } = useDialog();
  // const { activeWallet } = useWallet(triggerAlert);
  // const setWalletAddress = (walletAddr) => {
  //   setUserData((prevState) => {
  //     return { ...prevState, walletAddress: walletAddr };
  //   });
  // };

  // const connectWallet = async () => {
  //   if (!window.ethereum) {
  //     return setNoMetaMaskDialogueOpen(true);
  //   }
  //   if (!userData.walletAddress) {
  //     // Wallet address is not present
  //     try {
  //       triggerAlert(
  //         "info",
  //         "Wallet Address is not Registered! registering it..."
  //       );
  //       await instance.patch(
  //         `user/${userData.walletAddress}/add-wallet/${activeWallet}`
  //       );
  //       setWalletAddress(activeWallet);
  //       triggerAlert(
  //         "success",
  //         `Wallet ${activeWallet} is registered with twitterId ${userData.userId}`
  //       );
  //       isWalletConnected(true);
  //     } catch (err) {
  //       triggerAlert("error", err.response.data.message);
  //     }
  //   } else {
  //     if (activeWallet === userData.walletAddress) {
  //       setIsWalletConneted(true);
  //       triggerAlert("success", "Wallet is connected successfully");
  //     } else {
  //       triggerAlert(
  //         "error",
  //         `Wallet ${activeWallet} and registered wallet doesn't match! change your active wallet!`
  //       );
  //     }
  //   }
  // };

  const logout = async () => {
    try {
      setIsLoading(true);
      await instance.get("user/logout");
      triggerAlert("success", "Logout Successfull");
      window.location.reload();
    } catch (err) {
      !(
        err.name === "AxiosError" &&
        err.response?.data.message === "session does not exists!"
      ) && triggerAlert("error", "Logout Failed!");
    } finally {
      setIsLoading(false);
    }
  };
  const fetchUserData = async () => {
    try {
      const data = await instance.get("user/me");
      return data.data;
    } catch (err) {
      return { error: err };
    }
  };
  useEffect(() => {
    const validateSession = async () => {
      setIsLoading(true);
      const data = await fetchUserData();
      if (data.error) {
        console.warn(data.error.message);
        setUserData({ ...initialState, isFetching: false });
      } else {
        setUserData((prevState) => {
          return {
            ...prevState,
            ...data.data,
            isLoggedIn: true,
            isFetching: false,
          };
        });
      }
      setIsLoading(false);
    };
    validateSession();
  }, []);

  // useEffect(() => {
  //   setIsWalletConneted(false);
  //   const checkWalletId = userData.walletAddress?.toLowerCase();
  //   if (!activeWallet) {
  //     return setNoMetaMaskDialogueOpen(true);
  //   }
  //   if (userData.isLoggedIn) {
  //     if (checkWalletId !== activeWallet?.toLowerCase()) {
  //       triggerAlert(
  //         "warning",
  //         "Wallet Changed! Connect your wallet again to use certain features."
  //       );
  //     } else {
  //       setIsWalletConneted(true);
  //       triggerAlert("success", "Wallet Connected Successfully!");
  //     }
  //   }
  // }, [activeWallet]);

  // useEffect(() => {
  //   isWalletConnected ? setdialogOpen(true) : setdialogOpen(false);
  // }, [isWalletConnected]);

  return (
    <AuthContext2.Provider
      value={{
        ...userData,
        // activeWallet,
        fetchUserData,
        // setWalletAddress,
        logout,
        // connectWallet,
        isWalletConnected,
        setNoMetaMaskDialogueOpen,
        handleClose,
        isLoading,
        toggleAudio,
        allowAudio,
        showDialog
      }}
    >
      <MUIDialog />
      {props.children}
      {/* DialogTitle */}
      <Dialog
        open={dialogopen}
        onClose={() => {
          setdialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Welocome to Alien world
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* <Dialog
        open={NoMetaMaskDialogOpen}
        onClose={() => setNoMetaMaskDialogueOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No Metamask Wallet Found!
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
      <AlertComponent />
    </AuthContext2.Provider>
  );
};

export default AuthProvider;
