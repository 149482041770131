import * as React from "react";
import Loader from "../../Utils/Loader";
import NewPage from "../../Utils/NewPage";
import { faqData } from "./FaqData";
import "./faq.css";
import { Link } from "react-router-dom";

function Faq() {
  // const ctx = useContext(AuthContext);
  // const { walletAddress, connectWallet, isLoggedIn, activeWallet } = ctx;

  return (
    <NewPage title="FAQ - Node Aliens">
      <div>
        <Loader>
          <div>
            <div className="faqContainer">
              <h4>FAQ</h4>
              <div className="accordion" id="accordionExample">
                {faqData.map((question, ind) => {
                  return (
                    <div className="accordion-item" key={question.que}>
                      <h2 className="accordion-header" id={`heading${ind}`}>
                        <button
                          className={`accordion-button `}
                          type="button"
                          data-bs-toggle="collapse"
                          dangerouslySetInnerHTML={{ __html: question.que }}
                          data-bs-target={`#collapse${ind}`}
                          aria-expanded={ind === 0 ? "true" : "false"}
                          aria-controls={`collapse${ind}`}
                        />
                      </h2>
                      <div
                        id={`collapse${ind}`}
                        className={`accordion-collapse collapse ${
                          ind === 0 ? "show" : ""
                        }`}
                        aria-labelledby={`heading${ind}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          style={{
                            whiteSpace: "pre-wrap",
                          }}
                          dangerouslySetInnerHTML={{ __html: question.ans }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="accordion-item" key={"Is there a discord?"}>
                  <h2 className="accordion-header" id={`headingLink-d`}>
                    <button
                      className={`accordion-button `}
                      type="button"
                      data-bs-toggle="collapse"
                      // dangerouslySetInnerHTML={{__html: question.que}}
                      data-bs-target={`#collapseLink-d`}
                      aria-controls={`collapseLink-d`}
                    >
                      Is there a discord?
                    </button>
                  </h2>
                  <div
                    id={`collapseLink-d`}
                    className={`accordion-collapse collapse`}
                    aria-labelledby={`headingLink-d`}
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Yes. There is a discord. You can access it{" "}
                      <Link
                        to={"http://discord.gg/nodealiens"}
                        target="__blank"
                      >
                        here
                      </Link>
                      .{"\n\n"}In the Node Alien discord, you'll have acess to
                      information about presale, mint, upcoming drops, community
                      events, and much more. You'll get a chance to bondn with
                      the Node Alien community and learn about all we have to
                      offer the community.
                    </div>
                  </div>
                </div>
                <div className="accordion-item" key={"Is there a roadmap?"}>
                  <h2 className="accordion-header" id={`headingLink-r`}>
                    <button
                      className={`accordion-button `}
                      type="button"
                      data-bs-toggle="collapse"
                      // dangerouslySetInnerHTML={{__html: question.que}}
                      data-bs-target={`#collapseLink-r`}
                      aria-controls={`collapseLink-r`}
                    >
                      Is there a roadmap?
                    </button>
                  </h2>
                  <div
                    id={`collapseLink-r`}
                    className={`accordion-collapse collapse`}
                    aria-labelledby={`headingLink-r`}
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Yes. You can see it <Link to={"/roadmap"}>here</Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* {isLoggedIn &&
                walletAddress?.toLowerCase() !==
                  activeWallet?.toLowerCase() && (
                  <SpeedDial
                    className="metaMaskCircleBtn"
                    ariaLabel="SpeedDial basic example"
                    icon={
                      <img className="metaMaskCircleBtnIcon" src={metaMask} />
                    }
                    onClick={connectWallet}
                  />
                )} */}
            </div>
          </div>
        </Loader>
      </div>
    </NewPage>
  );
}

export default Faq;
