import { Alert, Snackbar, Stack } from "@mui/material";
import { useCallback, useState } from "react";

const useAlert = () => {
  const [open, setOpen] = useState(false);
  const [severity, setseverity] = useState();
  const [message, setmessage] = useState();

  /**** Snackbar *******/
  const triggerAlert = useCallback((severity, message) => {
    setseverity(severity);
    setmessage(message);
    setOpen(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const AlertComponent = () => (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
  return { AlertComponent, triggerAlert, handleClose };
};

export default useAlert;
