import { Route, Routes } from "react-router-dom";
import "./App.css";
import Nmatika from "./Component/Nmatika";
import ComingSoon from "./Component/ComingSoon";
import Faq from "./Component/Faq/Faq";
import Gallery from "./Component/Gallery/Gallery";
import Garden from "./Component/Garden/Garden";
import Home from "./Component/Home/Home";
import Loginerr from "./Component/LoginError/Loginerr";
import Middlepg from "./Component/Middle/Middlepg";
import Roadmap from "./Component/Roadmap";
import NotFound from "./Component/NotFound/NotFound";
import Second from "./Component/Second/Second";
import Spot from "./Component/Spot";
import Table from "./Component/Table/Table";
import Temple from "./Component/Temple/Temple";
import ProtectedRoute from "./Utils/ProtectedRoute";

function App() {
  const loadingContainer = document.getElementsByClassName("lds-ring")[0];
  loadingContainer.classList.add("d-none");
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/aliens" element={<ComingSoon />} /> */}
        <Route path="/temple" element={<Temple />} />
        <Route path="/garden" element={<Garden />} />
        <Route path="/table" element={<Table />} />
        <Route path="/middle" element={<Middlepg />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/nmatika" element={<Nmatika />} />
        <Route path="/second" element={<Second />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/loginerr" element={<Loginerr />} />
        <Route path="/spot" element={<ProtectedRoute children={<Spot />} />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
// export { ContextData };
